package com.sludg.client.components

/**
  * @author Ali S. (CM9000)
  */

import com.sludg.{Security, vue}
import com.sludg.client.components.Ticker.TickerComponent
import com.sludg.models.Config.AppLink
import com.sludg.services.PhoneApi
import com.sludg.vue.{EventBindings, Vue, _}
import org.log4s.getLogger

import scala.scalajs.js
import scala.scalajs.js.timers.SetIntervalHandle

object Ticker {

  import com.sludg.vue.RenderHelpers._

  private[components] val logger = getLogger

  type TickerComponent = VueComponent[_ <: TickerProps, _ <: Slots, _ <: ScopedSlots] with TickerData with TickerMethods with js.Object with TickerProps with WithRefs[_ <: Refs]

  def TickerRenderer(registrationName: String) = namedTag[TickerProps, EventBindings, ScopedSlots]("Ticker")

  def TickerComponent(apiCalls: PhoneApi, security: Security, loaderEventBus: Vue, otherApps: List[AppLink]) = {

    val data = new TickerData()
    val methods = new TickerMethods()

    VueComponent.builder
      .withData(data)
      .withMethods(methods)
      .withProps(js.Array("totalSeconds").asInstanceOf[TickerProps])
      .build(
        created = js.defined(c => {
          logger.info("Ticker created")
          c.localTotalSeconds = c.totalSeconds.get

          c.setIntervalHandle = Some(js.timers.setInterval(1000)(c.setTime()))

          c.$root.$on("reset-call-timer", ((e: String) => {
            js.timers.clearInterval(c.setIntervalHandle.get)
          }): js.Function1[String, Unit])


        }),
        components = js.Dynamic.literal(
        ),
        templateOrRender =
          Right((component, renderer) => {
            p(
              s"Call Duration: ${component.convertTimeInt(component.localTotalSeconds)}",
            ).render(renderer)
          })
      )
  }
}

class TickerData extends js.Object {
  var formattedTime: String = ""
  var localTotalSeconds: Int = 0
  var setIntervalHandle: Option[SetIntervalHandle] = None
}

trait TickerProps extends VueProps {
  val totalSeconds: js.UndefOr[Int] = js.undefined
}

object TickerProps {
  import scalajs.js.JSConverters._
  def apply(
           totalSeconds: Option[Int] = Some(0)
           ): TickerProps = js.Dynamic.literal(
    "totalSeconds" -> totalSeconds.map(js.Any.fromInt).orUndefined
  ).asInstanceOf[TickerProps]
}



class TickerMethods extends js.Object {
  def setTime(): Unit = {
    val component = this.asInstanceOf[TickerComponent]
    component.localTotalSeconds = component.localTotalSeconds+1
  }

  def convertTimeInt(unformatted: Int): String = {
    val unformattedTime = unformatted
    val s = unformattedTime.toInt % 60
    val m = (unformattedTime / 60) % 60
    val h = (unformattedTime / 60 / 60)
    val time = "%02.0f:%02.0f:%02.0f".format(h, m, s)
    time.toString()
  }
}




