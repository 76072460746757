package com.sludg.client.pages.settings

import com.sludg.vue.RenderHelpers.{div, nothing}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VCheckBoxProps
import org.scalajs.dom.raw.Event
import com.sludg.vue.RenderHelpers.{div, nothing}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import scala.scalajs.js
import com.sludg.services.{ApiCalls}
import com.sludg.model.SettingsModels.Setting
import com.sludg.vue.{
  EventBindings,
  RenderHelpers,
  RenderOptions,
  ScopedSlots,
  Vue,
  VueProps,
  WithRefs,
  _
}
import com.sludg.vue._
import com.sludg.model.SettingsModels.PickList
import com.sludg.model.SettingsModels.Selectable
import com.sludg.model.SettingsModels._
import com.sludg.model.Models._
import com.sludg.auth0.SludgToken
import com.sludg.util.models.SilhouetteModels.UserPhone
import com.sludg.client.pages.settings.SettingsMenuPage._
import com.sludg.client.pages.settings.SettingDisplayer.SettingDisplayerComponent
import java.nio.channels.Selector
import com.sludg.model.SettingsModels.ConfigurationType.LogCaseComment
import com.sludg.model.SettingsModels.Configuration.{FieldLogging, ClickToCallDevice, CaseComment}
import com.sludg.model.SettingsModelsSerializers._
import org.scalajs.dom.ext.LocalStorage

object SettingsPageRenderFunctions {

  import com.sludg.vue.RenderHelpers._

  // TODO move toe spa util
  val vSelect = namedTag[VueProps, EventBindings, ScopedSlots]("v-select")
  val vRadioGroup =
    namedTag[VueProps, EventBindings, ScopedSlots]("v-radio-group")
  val vRadio = namedTag[VueProps, EventBindings, ScopedSlots]("v-radio")

  def renderSettingsMenu(component: SettingsMenuPageComponent, api: Api) = {
    vList(
      RenderOptions(
        `class` = List(
          Left("#434241")
        )
      ),
      component.settings.map(setting => {
        Seq(
          Seq(vSubheader(s"${setting.name}")),
          setting.configurations.map(config => {
            if (config.isBoolean) {
              val configValue: Option[js.Any] = config.selection
                .asInstanceOf[Single[Boolean]]
                .selected
                .map(a => js.Any.fromBoolean(a.asInstanceOf[Boolean]))
              vListTile(
                RenderOptions(
                  on = Some(
                    EventBindings(
                      click = js.defined(e => {
                        SettingsMenuPage.logger.debug("Setting Clicked - clicked")
                        SettingsMenuPage.logger.debug(s"${setting}")
                        // Essentially finds the setting clicked and reverses its selection boolean and reassignes the settings list with the updated selection
                        // this could be generalised method for boolean Settings if i wasn't getting depression
                        SettingsMenuPage.logger.debug(s"${component.settings}")
                        component.settings =
                          SettingsUtil.updateCaseCommentSelection(component.settings, None, api)

                      })
                    )
                  )
                ),
                vListTileAction(
                  vCheckBox(
                    RenderOptions(
                      props = Some(
                        VCheckBoxProps(
                          `input-value` = configValue
                        )
                      )
                    )
                  )
                ),
                vListTileContent(
                  vListTileTitle(s"${config.name}"),
                  vListTileSubTitle(s"${config.description}")
                )
              )
            } else {
              div(
                vListTile(
                  RenderOptions(
                    on = Some(
                      EventBindings(
                        click = js.defined(e => {
                          component.$router.push(s"/settings/${config.configurationType}")
                        })
                      )
                    )
                  ),
                  vListTileAction(vIcon(config.icon)),
                  vListTileContent(
                    vListTileTitle(s"${config.name}"),
                    vListTileSubTitle(s"${config.description}")
                  )
                )
              )
            }
          }),
          if (component.settings.tail == Nil) Nil else Seq(vDivider)
        ).flatten
      })
    )
  }

  // Could probably use the selection type to determine the display type rather than that being separate
  def renderConfiguration(
      component: SettingDisplayerComponent,
      configuration: Configuration[Any]
  ): com.sludg.vue.RenderHelpers.NodeModifier[VueProps, EventBindings, ScopedSlots] = {
    div(
      h3(configuration.name),
      p(configuration.description),
      configuration.displayType match {
        case MultiSelect =>
          vAutocomplete[Any](
            RenderOptions(
              props = Some(
                VAutocompleteProps[Any](
                  `small-chips` = Some(true),
                  dense = Some(true),
                  items = Some(configuration.options),
                  chips = Some(true),
                  multiple = Some(true),
                  value = Some(Right(SettingsUtil.selectionToList(configuration.selection))),
                  itemValue = Some(Right(identity)),
                  itemText = Some(Right(configuration.itemToText)),
                  `return-object` = Some(true)
                )
              ),
              on = Some(
                VAutocompleteEventBindings(
                  input = js.defined(e => {
                    if (js.isUndefined(e)) { () }
                    else {
                      val selection = e.asInstanceOf[js.Array[Any]]
                      component.selectionData = Some(selection.toList)
                    }
                  })
                )
              )
            )
          )
        case PickList =>
          vCombobox[Any](
            RenderOptions(
              props = Some(
                VComboboxProps[Any](
                  items = Some(
                    configuration.options.asInstanceOf[List[Either[VComboboxProps.StaticItem, Any]]]
                  ),
                  clearable = Some(true),
                  placeholder = Some("Select Related"),
                  dense = Some(true),
                  solo = Some(true),
                  `item-value` = Some(Right(r => r))
                )
              )
            )
          )
        case Option =>
          div(
            vRadioGroup(
              RenderOptions(
                props = Some(
                  js.Dynamic
                    .literal(
                      "value" -> {
                        if (configuration.selection.asInstanceOf[Single[Any]].selected.isDefined) {
                          val up = configuration.selection.asInstanceOf[Single[Any]].selected.get
                          logger.debug(configuration.options.indexOf(up).toString())
                          up.asInstanceOf[js.Any]
                        } else {
                          logger.debug("Not defined")
                          ""
                        }
                      }
                    )
                    .asInstanceOf[VueProps]
                ),
                on = Some(
                  EventBindings(
                    change = js.defined(e => {
                      if (js.isUndefined(e)) {
                        logger.debug("Selected User phone is undefined")
                      } else {
                        val selection = e.asInstanceOf[Any]
                        logger.debug("input")
                        logger.debug(selection.toString)
                        component.selectionData = Some(selection)
                      }
                    })
                  )
                )
              ),
              if (configuration.options.nonEmpty) {
                configuration.options
                  .to(Seq)
                  .map(option => {
                    option match {
                      case userPhone: UserPhone => {
                        vRadio(
                          RenderOptions(
                            props = Some(
                              js.Dynamic
                                .literal(
                                  "label" -> s"${userPhone.userLabel} ${if (userPhone.defaultC2CPhone) "(Current)"
                                  else ""}",
                                  "value" -> userPhone.asInstanceOf[js.Any]
                                )
                                .asInstanceOf[VueProps]
                            )
                          )
                        )
                      }
                      case None => div("Setting selection has not formatter defined")
                    }
                  })
              } else {
                div("You are not logged into any device")
              }
            )
          )
        case _ => nothing
      }
    )
  }
}

