package com.sludg.client.pages.main

import com.sludg.client.components.callpanel.CallPanelProps
import com.sludg.client.pages.main.PhonePage.{PhonePageComponent, callPanel}
import com.sludg.models.CallControlModels._
import com.sludg.vue.RenderHelpers.{div, nothing, br, span}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import org.scalajs.dom.raw.Event
import com.sludg.model.Models.CallData
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.JSON
import com.sludg.model.Models.SelectOption
import com.sludg.salesforce._
import com.sludg.salesforce.SFObject.SFOtherObjectType
import com.sludg.auth0.SludgToken

/** Render Functions related to PhonePage
  */
object PhonePageRenderFunctions {

  /** Used to display snackbar Messages with some colour coding.
    *
    * @param component
    * @param renderer
    * @return
    */
  def snackBar(component: PhonePageComponent, renderer: CreateElement) = {
    PhonePage.logger.info("rendering snackbar")
    val result
        : RenderHelpers.NodeRenderer[_ >: VSnackbarProps <: VueProps, EventBindings, ScopedSlots] =
      component.snackBarMessage match {
        case Some(message) => {
          vSnackbar(
            RenderOptions(
              props = Some(
                VSnackbarProps(
                  value = Some(component.displaySnackBar),
                  color = Some(message.messageType match {
                    case DisplayMessageType.Error => "red"
                    case DisplayMessageType.Success => "green"
                    case DisplayMessageType.Removed => "primary"
                    case DisplayMessageType.Info => "green"
                  })
                )
              ),
              on = Some(
                EventBindings(
                  input = js.defined(e => {
                    component.displaySnackBar = false
                  })
                )
              )
            ),
            message.message,
            vButton(
              RenderOptions(
                props = Some(
                  VButtonProps(
                    color = Some("white"),
                    flat = Some(true)
                  )
                ),
                on = Some(
                  EventBindings(
                    click = js.defined(e => {
                      component.displaySnackBar = false
                    })
                  )
                )
              ),
              "Close"
            )
          )
        }
        case None => div(nothing)
      }
    result.render(renderer)
  }

  def callQueueTabs(
      component: PhonePageComponent,
      token: SludgToken
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      vCard(
        div(
          vTabs(
            RenderOptions(
              props = Some(
                js.Dynamic
                  .literal(
                    "color" -> "white",
                    "grow" -> true,
                    "height" -> "70",
                    // "hide-slider" -> true,
                    "icons-and-text" -> true,
                    "slider-color" -> "primary",
                    "next-icon" -> "arrow_forward_ios",
                    "prev-icon" -> "arrow_back_ios",
                    "show-arrows" -> true,
                    "value" -> component.vTabSelected
                  )
                  .asInstanceOf[VTabsProps]
              ),
              on = Some(
                EventBindings(
                  change = js.defined(e => {
                    PhonePage.logger.debug(s"VTabs change $e")
                    // vTabSelected here does nothing but maintain the slider animation correctly on tab click.
                    // The Logic in currentCallData watcher handles the selection from the queue.
                    component.vTabSelected = e.asInstanceOf[Int]
                  })
                )
              )
            ),
            callTabs(component)
          ),
          vTabsItems(
            RenderOptions(
              on = Some(
                EventBindings(
                  change = js.defined(e => {
                    PhonePage.logger.debug(s"VTabsItems Change ${e}")
                  })
                )
              )
            ),
            if (component.currentCallData.isDefined) {
              PhonePage.logger.info("Current Call Data is defined - Rendering Call Panel")
              val call = component.currentCallData.get
              vTabItem(
                callPanelRenderer(component, call, token)
              )
            } else {
              PhonePage.logger.info("Current Call Data is not defined - Not Rendering Call Panel")
              nothing
            }
          )
        )
      )
    )
  }

  /** Renders Scrollable CallTabs based on the `callQueue` Prop
    *
    * @param component
    * @return
    */
  private def callTabs(component: PhonePageComponent) = {
    component.callQueue.zipWithIndex.map {
      case (call: CallData, index: Int) => {

        val (icon, color) = call.lastEvent match {
          case a: CallReceived => ("phone_callback", "blue darken-2")
          case a: CallRingBack => ("phone_forwarded", "blue darken-2")
          case a: CallRinging => ("phone_callback", "blue darken-2")
          case b: CallEnd =>
            if (call.callActive) ("call_end", "red darken-2") else ("phone_missed", "red darken-2")
          case c: CallAnswered => ("phone_in_talk", "green darken-2")
          case d: CallDialed => ("phone_forwarded", "blue darken-2")
          case d: CallHold => ("phone_paused", "yellow darken-2")
          case d: CallResume => ("phone_in_talk", "green darken-2")
          case d: CallError => ("phone_disabled", "red")
          case d: CallForward => ("phone_forwarded", "blue darken-2")
          case t: CallTransfer => ("phone_forwarded", "blue darken-2")
          case _ => ("phone_in_talk", "green darken-2")
        }

        vTab(
          RenderOptions(
            on = Some(
              EventBindings(
                click = js.defined(e => {
                  // PhonePage.logger.info(s"Call Tab clicked with tag ${s"#tag-${call.callNumber}"}")
                  val selectedCall: CallData = call.copy(callActive = true)
                  PhonePage.logger.debug(s"Selected Call Data: ${selectedCall}")
                  PhonePage.logger.debug(s"Updating currentCallData")
                  component.currentCallData = Some(selectedCall)
                })
              )
            )
          ),
          div(
            span(
              s"${call.sfContact.map(s => s.Name.getOrElse(call.from)).getOrElse(call.from)}",
              br,
              s"${call.sfContact.flatMap(_.RelatedAccountName).getOrElse("")}"
            ) // Rendering Name with Account if present or just the Phone number
          )(
            RenderOptions[VueProps, EventBindings, ScopedSlots](
              style = Some(
                js.Dynamic.literal(
                  "color" -> "black"
                )
              )
            )
          ),
          vIcon(
            icon,
            RenderOptions(
              props = Some(
                VIconProps(
                  color = Some(color),
                  dark = Some(true)
                )
              )
            )
          )
        )
      }
      case _ => div("no match for list of queue")
    }
  }
  def callPanelRenderer(component: PhonePageComponent, call: CallData, token: SludgToken) = {
    PhonePage.logger.info("Call panel renderer")
    div(
      callPanel(
        RenderOptions(
          props = Some(
            CallPanelProps(
              call,
              Some(component.updatedLogOptions),
              token
            )
          ),
          on = Some(
            js.Dynamic
              .literal(
                "call-discarded" -> (((callData: Option[CallData]) =>
                  {
                    PhonePage.logger.info("Discarding call")
                    if (callData.isDefined) {
                      component.removeFromQueue(callData.get.Id, component)
                      component.vTabSelected = 0
                      component.currentCallData = None
                    }
                  }
                  )
                ),
                "call-wrapped" -> (((callId: String) =>
                  {
                    PhonePage.logger.info("Wrapping call")
                    component.removeFromQueue(callId, component)
                    component.vTabSelected = 0
                    component.currentCallData = None
                  }
                  )
                ),
                "sfContact-created" -> (((e: (String, SFContact)) =>
                  {
                    PhonePage.logger.info("SF Contact created and being updated")
                    if (component.currentCallData.isDefined) {
                      val currentCall = component.currentCallData.get
                      val upddatedCall = currentCall.copy(sfContact = Some(e._2))
                      component.displayMessage(
                        component,
                        SnackBarMessage("New Contact Created", DisplayMessageType.Success)
                      )
                      component.currentCallData = Some(upddatedCall)
                    }
                  }
                  )
                ),
                "sfContact-updated" -> (
                  (
                    (sfContact: Option[SFContact]) =>
                      {
                        if (component.currentCallData.isDefined) {
                          val currentCall = component.currentCallData.get
                          val updatedData = currentCall.copy(sfContact = sfContact)
                          component.currentCallData = Some(updatedData)
                          PhonePage.logger.info(s"sfContact updated CQ Pre: ${js.isUndefined(component.callQueue)}")
                          component.callQueue =
                            component.updateCallDataInQueue(component.callQueue, updatedData)
                          PhonePage.logger.info(s"sfContact updated CQ Post: ${js.isUndefined(component.callQueue)}")
                        }
                      }
                    )
                ),
                "sfObject-updated" -> (((updatedSfObject: Option[SFObject]) =>
                  {
                    PhonePage.logger.info("sfObject-updated")
                    PhonePage.logger.info(updatedSfObject.toString)
                    if (component.currentCallData.isDefined) {
                      val currentCall = component.currentCallData.get
                      val updatedData = currentCall.copy(selectedSfObject = updatedSfObject)
                      component.currentCallData = Some(updatedData)
                    }
                  }
                  )
                ),
                "logOption-selected" -> (
                  (
                    (fieldTypeWithSelection: (String, Option[SelectOption])) =>
                      {
                        if (component.currentCallData.isDefined) {
                          PhonePage.logger.info("LogOption Selected received")
                          val currentCall = component.currentCallData.get
                          PhonePage.logger.info(fieldTypeWithSelection.toString)
                          val updatedLogMap: Map[String, Option[SelectOption]] =
                            currentCall.selectedLogOptions.toMap
                              .updated(fieldTypeWithSelection._1, fieldTypeWithSelection._2)
                          PhonePage.logger.info("updated map")
                          PhonePage.logger.info(updatedLogMap.toString)
                          val updatedData =
                            currentCall.copy(selectedLogOptions = updatedLogMap.toJSMap)
                          PhonePage.logger.info(updatedData.toString)
                          component.currentCallData = Some(updatedData)
                        }
                      }
                    )
                )
              )
              .asInstanceOf[EventBindings]
          ),
          key = Some(s"${call.Id}")
        )
      )
    )
  }
}

case class SnackBarMessage(message: String, messageType: DisplayMessageType)

trait DisplayMessageType

// These types could be better
object DisplayMessageType {

  case object Error extends DisplayMessageType

  case object Success extends DisplayMessageType

  case object Removed extends DisplayMessageType

  case object Info extends DisplayMessageType

}

case class Button(name: String, oncClick: js.UndefOr[js.Function1[Event, Unit]])

