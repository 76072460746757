package com.sludg.client.pages.main

import java.time.ZoneId
import java.time.format.DateTimeFormatter
import cats.instances.future.catsStdInstancesForFuture
import com.sludg.client.VtslPhone
import com.sludg.client.components._
import com.sludg.client.components.callpanel.{CallPanel, CallPanelProps}
import com.sludg.client.pages.main.PhonePage.PhonePageComponent
import com.sludg.client.pages.main.PhonePageRenderFunctions._
import com.sludg.salesforce.JsonDeserializers._
import com.sludg.helpers.AppSetup._
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.services.{EventBus, EventBusEvent, PhoneApi}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{EventBindings, RenderOptions, ScopedSlots, Vue, VueProps, WithRefs, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.Security
import com.sludg.models.CallControlModels.{
  CallAnswered,
  CallDialed,
  CallEnd,
  CallError,
  CallEvent,
  CallHold,
  CallReceived,
  CallResume,
  CallRingBack,
  CallRinging,
  CallTransfer,
  PresenceEvent
}
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import com.sludg.models.Status.PhoneStatus.Connected
import com.sludg.models.Status.{PhoneStatus, UserStatus}
import com.sludg.salesforce._
import org.log4s.getLogger
import play.api.libs.json.Json
import com.sludg.util.models.SilhouetteModels.{UserPhone}
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.JSON
import org.scalajs.dom.window
import org.scalajs.dom.raw.StorageEvent
import org.scalajs.dom.ext.LocalStorage
import org.scalajs.dom
import com.sludg.model.Models.CallData
import com.sludg.client.OpenCtiUtil
import play.api.libs.json.JsArray
import play.api.libs.json.JsString
import play.api.libs.json.Reads.JsArrayMonoid
import play.api.libs.json.JsSuccess
import play.api.libs.json.JsValue
import com.sludg.model.Models.SelectOption
import monix.eval.Task.Options
import com.sludg.model.Models
import com.sludg.salesforce.SFOpportunity.SFOpportunityType
import com.sludg.salesforce.SFLead.SFLeadType
import com.sludg.salesforce.SFCase.SFCaseType
import com.sludg.salesforce.SFContact.SFContactType
import cats.data.EitherT
import cats.data._
import cats.syntax.all._
import cats.effect.IO
import scala.concurrent.Future
import com.sludg.vuetify.components.VComboboxProps
import com.sludg.salesforce.SFObject.SFOtherObjectType
import com.sludg.model.Models._
import com.sludg.model.SettingsModels._
import com.sludg.client.pages.settings.SettingsUtil
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.helpers.LoadingFuture
import com.sludg.util.TokenUtil
import java.util.Timer
import com.sludg.client.pages.helper.Helper
import com.sludg.services.Websockets
import scala.concurrent.ExecutionContext
import scala.concurrent.duration._
import com.sludg.services.WebsocketMessage
import scala.util.control.NoStackTrace
import retry._
import cats.effect.concurrent.Deferred
import scala.scalajs.js.JSConverters._

/** @author Ali S. (CM9000)
  * Essentialy 'Root' Component
  * Main PhonePage component has 5 responsibilities:
  * 1. Initialise Salesforce Interop to allow for salesforce methods and event listeners to be used
  * 2. Esbalish connection to Silhoutte EventBus to allow for call events
  * 3. Store a CallEvents buffer to allow temporary history of calls (this also helps in estblishing call transfers in call groups)
  * 4. Searches salesforce for the number in call data to find related contact and `SFObjects` (e.g. Cases, Opportunities)
  * 5. Handles events related to CurrentCallData received from CallPanel
  */

object PhonePage {

  import com.sludg.vue.RenderHelpers._

  private[pages] val logger = getLogger

  type PhonePageComponent = VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
    with PhonePageData
    with PhonePageMethods
    with js.Object
    with VueProps
    with WithRefs[_ <: Refs]

  val vtslPhone = VtslPhone.VtslPhoneRenderer("VtslPhone")
  val statusBar = StatusBar.StatusBarRenderer("StatusBar")
  val dialPad = DialPad.DialPadRenderer("DialPad")
  val ticker = Ticker.TickerRenderer("Ticker")
  val vSelect = namedTag[VueProps, EventBindings, ScopedSlots]("v-select")
  val callPanel = namedTag[CallPanelProps, EventBindings, ScopedSlots]("CallPanel")

  def PhonePageRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("PhonePage")

  def PhonePageComponent(
      apiCalls: PhoneApi,
      websockets: Websockets,
      security: Security,
      loaderEventBus: Vue,
      otherApps: List[AppLink],
      token: SludgToken
  )(implicit ec: ExecutionContext) = {

    val data = new PhonePageData()
    val methods = new PhonePageMethods()

    VueComponent.builder
      .withData(data)
      .withMethods(methods)
      .build(
        watch = new PhonePageWatcher(),
        destroyed = js.defined(c => {
          c.refreshTimer match {
            case Some(timer) =>
              logger.debug("component destroyed and token refresh timer cancelled"); timer.cancel()
            case None => logger.debug("component destroyed but not timer was found")
          }
          c.connectionCanceller.foreach(_.unsafeRunAsyncAndForget())
        }),
        created = js.defined(c => {
          logger.info("Phone page created")
          c.token = Some(token)
          PhonePage.logger.info(s"init CQ Pre: ${js.isUndefined(c.callQueue)}")
          c.callQueue = List()
          PhonePage.logger.info(s"init CQ Post: ${js.isUndefined(c.callQueue)}")

          LoadingFuture.withLoading(
            loaderEventBus,
            Helper
              .tokenValidator(security, apiCalls, token)
              .map({
                case t @ Some(validToken) => {
                  c.token = t
                  c.getClickToCallDevice(apiCalls)(validToken)
                    .map({
                      case userOpt =>
                        c.selectedDevice = userOpt
                    })
                  // Establishing connection to the Silhouette eventBus
                  c.connectToSilhouetteEventBus(websockets, apiCalls, c.token.get)(ec)
                  c.refreshTimer = Some(
                    TokenUtil.tokenRefresher(
                      validToken.tokenString,
                      (newToken: Option[SludgToken]) => {
                        c.token = newToken
                      },
                      security
                    )
                  )
                }
                case None => security.logout()
              })
          )
          // Event listener for storage events fired from the main tab to other tabs
          window.onstorage = e => {
            e.key match {
              case "callQueue" => {
                val listOfIds: List[String] = Json.parse(e.newValue).validate[List[String]] match {
                  case JsSuccess(v, _) => v
                  case _ => Nil
                }
                PhonePage.logger.info(s"onstorage CQ Pre: ${js.isUndefined(c.callQueue)}")
                // Filters the calls based on Ids that don't exist on the main tab
                c.callQueue = c.callQueue.filter(a => listOfIds contains a.Id)
                PhonePage.logger.info(s"onstorage CQ Post: ${js.isUndefined(c.callQueue)}")
              }
              case _ => () // Ignore other storage events
            }
          }
          // Getting Call Log Options based on what is in Settings
          logger.info("Getting Log options")
          LoadingFuture.withLoading(
            loaderEventBus,
            c.getLogOptions()
              .map(newMap => {
                logger.info("Assigning log options")
                c.updatedLogOptions = newMap
              })
          )

          c.$root.$on(
            "display-message",
            (msg => {
              c.displayMessage(c.asInstanceOf[PhonePageComponent], msg)
            }): js.Function1[SnackBarMessage, Unit]
          )
        }),
        components = js.Dynamic.literal(
          "VtslPhone" -> VtslPhone
            .VtslPhoneComponent(apiCalls, security, loaderEventBus, otherApps),
          "StatusBar" -> StatusBar
            .StatusBarComponent(apiCalls, security, loaderEventBus, otherApps),
          "Ticker" -> Ticker.TickerComponent(apiCalls, security, loaderEventBus, otherApps),
          "DialPad" -> DialPad.DialPadComponent(apiCalls, security, loaderEventBus, otherApps),
          "CallPanel" -> CallPanel.CallPanelComponent(apiCalls, security, loaderEventBus, otherApps)
        ),
        templateOrRender = Right((component, renderer) => {
          vtslPhone(
            RenderOptions(
              props = Some(
                new VtslAppProps(
                  title = js.defined("Vtsl Phone"),
                  spacedToolbar = true,
                  temporaryDrawer = true
                )
              ),
              scopedSlots = Some(
                {
                  new VtslAppScopedSlots {
                    override val toolbar: js.UndefOr[js.Function1[Option[Any], VNode]] =
                      js.defined(_ => {
                        vLayout(
                          vFlex(
                            component.selectedDevice match {
                              case Some(u) => s"Device: ${u.userLabel}"
                              case None => s"No CTC Device"
                            }
                          ),
                          vSpacer(),
                          vFlex(
                            statusBar(
                              RenderOptions(
                                props = Some(
                                  StatusBarProps(
                                    statusTitle = None,
                                    status = component.phoneStatus
                                  )
                                )
                              )
                            )
                          ),
                          vFlex()
                        ).render(renderer)
                      })
                    override val default: js.UndefOr[js.Function1[Option[Any], VNode]] =
                      js.defined(tenantOpt => {
                        div(
                          if (component.callQueue.nonEmpty) {
                            component.token match {
                              case Some(token) => callQueueTabs(component, token)
                              case None => security.logout(); nothing
                            }
                          } else div(nothing),
                          snackBar(component, renderer)
                        ).render(renderer)
                      })
                  }
                }
              )
            )
          ).render(renderer)
        })
      )
  }

  case class ListRenderObject[A <: SFObject](
      listItems: List[A] = Nil,
      listItemValue: A => Any,
      listItemText: A => String
  )
}

class PhonePageData extends js.Object {

  var token: Option[SludgToken] = None
  var refreshTimer: Option[Timer] = None

  // Settings
  var updatedLogOptions: Map[SelectOption, List[SelectOption]] = Map()
  var selectedDevice: Option[UserPhone] = None

  // Call Events
  var currentCallData: Option[CallData] = None
  var callInitiated: Boolean = false
  var connected: Boolean = false
  var callQueue: List[CallData] = List[CallData]()
  // vTabSelected here does nothing but maintain the slider animation correctly on tab click.
  var vTabSelected = 0

  // Connection
  var connectionCanceller: Option[IO[Unit]] = None

  // Notification
  var phoneStatus: Option[PhoneStatus] = None
  var displaySnackBar: Boolean = false
  var snackBarMessage: Option[SnackBarMessage] = None

  // Salesforce
  var relatedCases: List[SFCase] = List()
  var relatedOpportunities: List[SFOpportunity] = List()
  var relatedAccounts: List[SFAccount] = List()
  var allowedObjects: List[String] = List()

}
class PhonePageMethods extends js.Object {

  import monix.execution.Scheduler.Implicits.global

  /** Utility Method to update CallData in in a call queue `List[CallData]` based on CallData Id
    *
    * @param currentCallQueue
    * @param updatedCallData
    * @return
    */
  def updateCallDataInQueue(
      currentCallQueue: List[CallData],
      updatedCallData: CallData
  ): List[CallData] = {
    currentCallQueue.find(c => c.Id == updatedCallData.Id) match {
      case Some(callData) =>
        val index: Int = currentCallQueue.indexOf(callData)
        currentCallQueue.patch(index, List(updatedCallData), 1)
      case None =>
        currentCallQueue
    }
  }

  def makeCall(callingTo: String, apiCalls: PhoneApi, isExternal: Boolean)(implicit
      token: SludgToken
  ): Future[Option[String]] = {
    val component = this.asInstanceOf[PhonePageComponent]

    OptionT
      .fromOption[Future](token.subscribers.headOption)
      .flatMap(sub =>
        OptionT(
          apiCalls
            .makeCall(
              sub.tid,
              sub.ext,
              callingTo,
              isExternal
            )
            .map(_.fold(c => None, r => r))
        )
      )
      .map(_.callId)
      .value

  }

  def removeFromQueue(silhouetteId: String, component: PhonePageComponent) = {
    PhonePage.logger.info("removing from queue")
    PhonePage.logger.info(s"removing 2 CQ Pre: ${js.isUndefined(component.callQueue)}")
    component.callQueue = component.callQueue.filterNot(c => c.Id == silhouetteId)
    PhonePage.logger.info(s"removing 2 CQ Post: ${js.isUndefined(component.callQueue)}")
  }

  def displayMessage(component: PhonePageComponent, snackbarMessage: SnackBarMessage) = {
    component.snackBarMessage = Some(snackbarMessage)
    component.displaySnackBar = true
  }

  /** Takes Map of SFObjects and converts it into list of VComboBoxProp
    *
    * @param sfObjectMap
    * @return
    */
  def getSelectableSFObjects(
      sfObjectMap: Map[SFObjectType, Seq[SFObject]]
  ): List[Either[VComboboxProps.StaticItem, SFObject]] = {

    val sfCase: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFCaseType, Nil).nonEmpty) {
        val s: List[Right[Nothing, SFObject]] =
          sfObjectMap.getOrElse(SFCaseType, Nil).map(c => Right(c)).toList
        Left(new VComboboxProps.StaticItem("Cases")) :: s
      } else Nil

    val sfLead: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFLeadType, Nil).nonEmpty) {
        val s: List[Right[Nothing, SFObject]] =
          sfObjectMap.getOrElse(SFLeadType, Nil).map(c => Right(c)).toList
        Left(new VComboboxProps.StaticItem("Leads")) :: s
      } else Nil

    val sFOpportunity: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFOpportunityType, Nil).nonEmpty) {
        Left(new VComboboxProps.StaticItem("Opportunities")) :: sfObjectMap
          .getOrElse(SFOpportunityType, Nil)
          .map(c => Right(c))
          .toList
      } else Nil

    val accounts: Seq[SFAccount] = sfObjectMap
      .getOrElse(SFAccount.SFAccountType, Nil)
      .map(_.asInstanceOf[SFAccount])

    val sFOther: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFOtherObjectType, Nil).nonEmpty) {
        Left(new VComboboxProps.StaticItem("MISC (not considered)")) :: sfObjectMap
          .getOrElse(SFOtherObjectType, Nil)
          .map(c => Right(c))
          .toList
      } else Nil

    sfCase ::: sFOpportunity ::: sfLead ::: sFOther
  }

  /** Searches salesforce based on the call.from `SFContact` related to the phone number and its related Properties from Salesforce and updates Call Data`
    * Returns and updated Call Data with salesforce data.
    * @param call // CallData
    */
  def searchContact(call: CallData): EitherT[Future, List[ErrorMessage], CallData] = {
    PhonePage.logger.debug("Searching Contacts")
    PhonePage.logger.debug(s"With search query ${call.from} and call type ${call.callType}")

    val component = this.asInstanceOf[PhonePageComponent]
    val result = EitherT[Promise, List[ErrorMessage], CallData](Promise())

    OpenCtiUtil.searchAndScreenPop(call.from, call.callType)(callBack = e => {
      if (e.success) {
        PhonePage.logger.info("Search contact success")
        PhonePage.logger.info(JSON.stringify(e.returnValue))

        val SFDynamic = e.returnValue

        val objectArray: js.Array[js.Dynamic] =
          OpenCtiUtil.convertJsDyanmicObjectToArray(e.returnValue)

        val splitSFObjects: Map[SFObjectType, Seq[SFObject]] =
          OpenCtiUtil.convertAndSeparateSFObjects(objectArray)

        val relatedContactsObjects = splitSFObjects.getOrElse(SFContactType, Nil)

        val relatedContacts: List[SFContact] =
          if (relatedContactsObjects.nonEmpty)
            relatedContactsObjects.map(_.asInstanceOf[SFContact]).toList
          else Nil

        val relatedCasesObjects = splitSFObjects.getOrElse(SFCaseType, Nil)

        val relatedCases: List[SFCase] =
          if (relatedCasesObjects.nonEmpty)
            relatedCasesObjects.map(_.asInstanceOf[SFCase]).toList
          else Nil

        val relatedCasesNotClosed = relatedCases.filterNot(c => c.Status.contains("Closed"))

        val supportCases = relatedCasesNotClosed.filterNot(c => c.Type.contains("Support"))

        val provisioningCases =
          relatedCasesNotClosed.filterNot(c => c.Type.contains("Provisioning"))

        val relatedOpportunityObjects =
          splitSFObjects.getOrElse(SFOpportunityType, Nil)

        val relatedLeadsObjects = splitSFObjects.getOrElse(SFLeadType, Nil)

        val relatedLeads: List[SFLead] =
          if (relatedLeadsObjects.nonEmpty)
            relatedLeadsObjects.map(_.asInstanceOf[SFLead]).toList
          else Nil

        val relatedOpportunities: List[SFOpportunity] =
          if (relatedOpportunityObjects.nonEmpty)
            relatedOpportunityObjects.map(_.asInstanceOf[SFOpportunity]).toList
          else Nil

        val relatedSFObjects: List[SFObject] =
          supportCases ::: relatedOpportunities ::: relatedLeads

        val sfContactFound: Option[SFContact] =
          if (relatedContacts.isEmpty && relatedLeads.nonEmpty) {
            Some(mapLeadToContact(relatedLeads.head))
          } else if (relatedContacts.size == 1) { relatedContacts.headOption }
          else None

        val selectableObjects = getSelectableSFObjects(splitSFObjects)

        val updatedCall: CallData = call.copy(
          sfContact = sfContactFound,
          relatedContacts = relatedContacts,
          relatedSfObjects = relatedSFObjects,
          searchedSalesforce = true,
          selectableSFObjects = selectableObjects
        )

        result.value.success(Right(updatedCall))

      } else {
        PhonePage.logger.debug("Failed to search")
        PhonePage.logger.debug(JSON.stringify(e.errors))
        // Error
        component.$root.$emit(
          "display-message",
          SnackBarMessage(
            "Server Error: Failed to search contacts",
            DisplayMessageType.Error
          )
        )
        result.value.success(Left(e.errors.toList))
      }
    })

    for (value <- EitherT(result.value.future)) yield (value)
  }

  def mapLeadToContact(sfLead: SFLead): SFContact = {
    new SFContact(
      id = sfLead.id,
      Name = sfLead.Name,
      Phone = sfLead.Phone,
      RelatedAccountName = sfLead.Company
    )
  }

  // Gets LogOptions from Salesforce based on the CallLog Configurations Selected in Settings
  def getLogOptions(): Future[Map[SelectOption, List[com.sludg.model.Models.SelectOption]]] = {
    PhonePage.logger.info("Gettomg log options")
    val c = this.asInstanceOf[PhonePageComponent]

    // Load Log Options Fields from Local Storage
    import com.sludg.model.SettingsModelsSerializers._
    val logFieldSetting: Option[ListSelection[SelectOption]] =
      SettingsUtil.getSelection[ListSelection[SelectOption]](LogField, LocalStorage)
    PhonePage.logger.info("LOGGING OPTIONS")
    PhonePage.logger.info(logFieldSetting.toString)
    if (logFieldSetting.isDefined) {
      val logFieldOptions = logFieldSetting.get.selected
      val getOptions: List[scala.concurrent.Future[Either[List[
        com.sludg.salesforce.ErrorMessage
      ], (SelectOption, List[com.sludg.model.Models.SelectOption])]]] =
        logFieldOptions.map(selOpt => {
          PhonePage.logger.info("Forming Query")

          val newthing = for {
            fieldValues <- OpenCtiUtil.getOptionsForField(Some(selOpt.value))
          } yield {
            (selOpt, fieldValues)
          }
          newthing.value
        })
      val futureOfListEither: Future[
        List[Either[List[ErrorMessage], (Models.SelectOption, List[Models.SelectOption])]]
      ] = Future.sequence(getOptions)
      val futureOfListOption = futureOfListEither.map(fut =>
        fut.map(e =>
          e match {
            case Right(v) => Some(v)
            case Left(b) => None
          }
        )
      )

      val futureOfListTuple: scala.concurrent.Future[List[
        (SelectOption, List[com.sludg.model.Models.SelectOption])
      ]] = futureOfListOption.map(_.flatten)

      futureOfListTuple.map(a => {
        PhonePage.logger.info("Fetching Call Log Options")
        val newMap: Map[SelectOption, List[com.sludg.model.Models.SelectOption]] =
          a.map(a => (a._1, a._2)).toMap
        newMap
      })
    } else {
      Future.successful(Map[SelectOption, List[com.sludg.model.Models.SelectOption]]())
      // Do nothing if no Options are defined
    }
  }

  def getClickToCallDevice(api: PhoneApi)(implicit token: SludgToken) = {
    val component = this.asInstanceOf[PhonePageComponent]

    api
      .getUserPhones(token.tenants(0), token.subscribers(0).ext.toInt)
      .map({
        case Right(value) => value.filter(u => u.defaultC2CPhone).headOption
        case Left(value) => None
      })
  }

  def connectToSilhouetteEventBus(websockets: Websockets, api: PhoneApi, sludgToken: SludgToken)(
      implicit ec: ExecutionContext
  ) = {
    val component = this.asInstanceOf[PhonePageComponent]

    implicit val cs = IO.contextShift(ec)
    implicit val sleep = Sleep.sleepUsingTimer(IO.timer(ec))

    val clickToDialEnabler = PhonePageUtil.retryReportingToSnackbarOnError(
      component,
      "An error occurred trying to enable click to dial",
      PhonePageUtil.enableClickToDial
    ) >> PhonePageUtil.initialiseClickToCallCallback(component, api, sludgToken)

    val eventListener = PhonePageUtil.retryReportingToSnackbarOnError(
      component,
      "An error occurred trying to connect",
      for {
        token <- IO { component.token }.flatMap(
          _.fold(
            IO.raiseError[SludgToken](
              PhonePageUtil.ConnectionError("Cannot connect due to a lack of valid token")
            )
          )(IO.pure(_))
        )
        socket <- websockets.tows(implicitly, token) match {
          case Left(value) =>
            IO.raiseError(
              PhonePageUtil.ConnectionError("User not linked - please link via User Portal")
            )
          case Right(value) =>
            value.evalMap(PhonePageUtil.websocketProcessor(component)).compile.drain
        }
      } yield ()
    )

    component.connectionCanceller = Some(
      (eventListener, clickToDialEnabler).parTupled.unsafeRunCancelable {
        case Left(value) =>
          PhonePage.logger.error(value)("The connection terminated unexpectedly.")
          component.displayMessage(
            component,
            SnackBarMessage(
              "Connection to the server has been lost. Please refresh the page",
              DisplayMessageType.Error
            )
          )
          PhonePage.logger.info("The connection terminated.")
        case Right(_) =>
          component.displayMessage(
            component,
            SnackBarMessage(
              "Connection to the server has been lost. Please refresh the page",
              DisplayMessageType.Error
            )
          )
      }
    )

  }
}

class PhonePageWatcher() extends js.Object {

  def callQueue(queue: List[CallData], previous: List[CallData]) = {
    val component = this.asInstanceOf[PhonePageComponent]
    if (js.isUndefined(queue)) {
      PhonePage.logger.info("Call Queue not defined")
    } else {
      PhonePage.logger.info("CallQueue Watcher - Call Queue updated")
      PhonePage.logger.info(queue.toString)

      if (queue.size != previous.size) {
        // If there is no calls added or removed

        val jsonOfIds: JsValue =
          Json.toJson(queue.map(_.Id): List[String]).validate[JsArray] match {
            case JsSuccess(array, _) => array
            case _ => JsArray()
          }

        /** Updates the LocalStorage with latest json array of CallData Ids
          * These Ids are used to track
          */

        LocalStorage.update("callQueue", jsonOfIds.toString())
      }

      if (queue.nonEmpty) {
        PhonePage.logger.debug("Call Queue Watcher - Call Queue")
        PhonePage.logger.debug(queue.toString)
      } else {
        PhonePage.logger.debug("Call Queue Empty!")
      }
    }
  }

  def selectedLogOptions(selection: Map[String, Option[SelectOption]]) = {
    val component = this.asInstanceOf[PhonePageComponent]
    if (js.isUndefined(selection)) {
      PhonePage.logger.info("selectedLogOptions not defined")
    } else {
      PhonePage.logger.info("selectedLogOptions UPDATE")
      PhonePage.logger.info(selection.toString)
      component.currentCallData =
        component.currentCallData.map(cd => cd.copy(selectedLogOptions = selection.toJSMap))
    }
  }

  def currentCallData(event: Option[CallData], previous: Option[CallData]) = {
    val component = this.asInstanceOf[PhonePageComponent]
    import scala.concurrent.ExecutionContext.Implicits.global
    if (js.isUndefined(event)) {
      PhonePage.logger.info("currentCall Event not defined")
    } else {
      if (event.isDefined) {

        val call = event.get

        PhonePage.logger.info("currentCallData Watcher - currentCall Changed")
        PhonePage.logger.debug("Previously ")
        PhonePage.logger.debug(previous.toString)
        PhonePage.logger.info("Currently")
        PhonePage.logger.info(call.toString)

        if (call.searchedSalesforce) {
          PhonePage.logger.info("Already searched salesforce")
          PhonePage.logger.info("Updating Queue")
          component.callQueue = component.updateCallDataInQueue(component.callQueue, call)
        } else {

          for (updatedCall <- component.searchContact(call)) yield {
            // Assignment
            PhonePage.logger.info("Searched Salesforce")
            PhonePage.logger.info("Updating Queue")
            component.currentCallData = Some(updatedCall)
            component.callQueue = component.updateCallDataInQueue(component.callQueue, updatedCall)
          }
        }

      } else {
        PhonePage.logger.info("Current Call Event Empty! so auto selecting")
        PhonePage.logger.info(
          component.callQueue.headOption.map(c => c.copy(callActive = true)).toString
        )
        component.currentCallData =
          component.callQueue.headOption.map(c => c.copy(callActive = true))
      }
    }
  }
}
