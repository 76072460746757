package com.sludg.client.components

/**
  * @author Ali S. (CM9000)
  */

import com.sludg.Security
import com.sludg.models.Config.AppLink
import com.sludg.models.Status
import com.sludg.models.Status.{PhoneStatus, UserStatus}
import com.sludg.services.PhoneApi
import com.sludg.vue.{EventBindings, RenderOptions, Vue, _}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VIconProps
import com.sludg.vuetify.components.grid.VGridProps
import org.log4s.getLogger

import scala.scalajs.js

/**
 * Status bar component for rendereing status
 */

object StatusBar {

  import com.sludg.vue.RenderHelpers._

  private[components] val logger = getLogger

  type StatusBarComponent = VueComponent[_ <: StatusBarProps, _ <: Slots, _ <: ScopedSlots] with StatusBarData with StatusBarMethods with js.Object with StatusBarProps with WithRefs[_ <: Refs]

  def StatusBarRenderer(registrationName: String) = namedTag[StatusBarProps, EventBindings, ScopedSlots]("StatusBar")

  val vToolTip = namedTag[VueProps, EventBindings, ScopedSlots]("v-tooltip")

  def StatusBarComponent(apiCalls: PhoneApi, security: Security, loaderEventBus: Vue, otherApps: List[AppLink]) = {

    import com.sludg.FieldExtractor

    VueComponent.builder
      .withData(new StatusBarData())
      .withMethods(new StatusBarMethods())
      .withPropsAs[StatusBarProps]
      .build(
        created = js.defined(c => {
          logger.info("StatusBar created")
        }),
        templateOrRender =
          Right((component, renderer) => {
             div(
                  vToolTip(
                    RenderOptions(
                      props = Some(js.Dynamic.literal(
                        "bottom" -> true
                      ).asInstanceOf[VueProps]),
                    ),
                    template(
                      RenderOptions(
                        slot = Some("activator")
                      ),
                       vIcon(component.status.toOption match {
                  case Some(u: UserStatus) => u.icon
                  case Some(p: PhoneStatus) => p.icon
                  case _ => "brightness_1"
                }, RenderOptions(
                  props=Some(VIconProps(
                    color = component.status.toOption match {
                      case Some(s: Status) => Some(s.indicatorColor)
                      case _ => Some("red")
                    },
                    small = Some(true),
                    right = Some(true)
                    ))))
                    ),
                    span(
                      s"${if (component.statusTitle.isDefined) s"${component.statusTitle.get}: " else ""} ${if (component.status.isDefined) component.status.get.text + " " else "Unavailable "}"
                      )
                  ),
              ).render(renderer)
          })
      )
  }
}

class StatusBarData extends js.Object {
}

trait StatusBarProps extends VueProps {
  val statusTitle: js.UndefOr[String]
  val status: js.UndefOr[Status]
}

object StatusBarProps {
  import scala.scalajs.js.JSConverters._

  def apply(
             status: Option[Status] = None,
             statusTitle: Option[String] = None,
           ) = {
    js.Dynamic.literal(
      "statusTitle" -> statusTitle.map(js.Any.fromString).orUndefined,
      "status" -> status.map(_.asInstanceOf[js.Object]).orUndefined
    ).asInstanceOf[StatusBarProps]
  }
}

class StatusBarMethods extends js.Object {
}




