package com.sludg.client.pages.settings

/** @author Ali S. (CM9000)
  */
import java.time.ZoneId
import java.time.format.DateTimeFormatter
import cats.data.EitherT
import cats.instances.future.catsStdInstancesForFuture
import com.sludg.client.VtslPhone
import com.sludg.client.components._
import com.sludg.client.components.callpanel.{CallPanel, CallPanelProps}
import com.sludg.client.pages.settings.SettingDisplayer.SettingDisplayerComponent
import com.sludg.salesforce.JsonDeserializers._
import com.sludg.helpers.AppSetup._
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.services.{ApiCalls, EventBus, EventBusEvent, PhoneApi}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{
  EventBindings,
  RenderHelpers,
  RenderOptions,
  ScopedSlots,
  Vue,
  VueProps,
  WithRefs,
  _
}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.Security
import com.sludg.client.pages.main.PhonePage.PhonePageComponent
import com.sludg.models.CallControlModels._
import com.sludg.models.Status.PhoneStatus.Connected
import com.sludg.models.Status.{PhoneStatus, UserStatus}
import com.sludg.salesforce._
import com.sludg.util.models.SilhouetteModels.{UserPhone, Tenant}
import org.log4s.getLogger
import play.api.libs.json.Json
import play.api.libs.json.JsSuccess
import monix.execution.Scheduler.Implicits.global
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.JSON
import org.scalajs.dom.ext.LocalStorage
import org.scalajs.dom.window
import js.JSConverters._
import com.sludg.util.models.Events.CallOriginatedEvent
import com.sludg.client.pages.settings.SettingsUtil._
import com.sludg.model.Models._
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.models.Models.AccessForbidden
import java.nio.channels.SelectionKey
import com.sludg.vue.rendering.RouterView
import scala.scalajs.js.UndefOr
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import com.sludg.model.SettingsModels.ConfigurationType
import com.sludg.vuetify.components.VComboboxProps
import com.sludg.model.SettingsModels.Configuration
import com.sludg.components.VTSLApp
import java.util.Timer
import com.sludg.util.TokenUtil
import com.sludg.client.pages.helper.Helper
import com.sludg.helpers.LoadingFuture

/** SettingsDisplayer renders a `Configuration` based on configurationType sent in the route `/settings/configurationType`
  * This configurationType is then used to find the respective `Configuration` from `defaultSettings`(List[Configuration[_]]) in `SettingsUtil`
  *
  * After getting the Configuration. SettingsDisplayer has 5 responsibilities:
  * 1. Render the configuration based on its `DisplayType`
  * 2. Fetch the previous input of the configuration from LocalStorage/ApiCalls and display the preivous configuration values pre-selected.
  * 3. Save the input for the configuration in `selectionData` var
  * 4. Once the user goes back via the `toolBarButton` ScopedSlot button. `saveConfig` method is called to save the updated input for that configuration.
  */
object SettingDisplayer {

  import com.sludg.vue.RenderHelpers._
  import com.sludg.vuetify.components.VButtonProps

  private[pages] val logger = getLogger

  type SettingDisplayerComponent =
    VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
      with SettingDisplayerData
      with js.Object
      with VueProps
      with WithRefs[_ <: Refs]

  val vtslPhone = VtslPhone.VtslPhoneRenderer("VtslPhone")
  val statusBar = StatusBar.StatusBarRenderer("StatusBar")
  val callPanel =
    namedTag[CallPanelProps, EventBindings, ScopedSlots]("CallPanel")
  val appRenderer = VTSLApp.appRenderer("VtslApp")

  def SettingDisplayerRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("SettingDisplayer")

  def SettingDisplayerComponent(
      apiCalls: ApiCalls,
      security: Security,
      loaderEventBus: Vue,
      otherApps: List[AppLink],
      token: SludgToken
  ) = {

    val data = new SettingDisplayerData()
    val watcher = new SettingDisplayerWatcher()

    import play.api.libs.json._
    import com.sludg.model.SettingsModelsSerializers._
    VueComponent.builder
      .withData(data)
      .build(
        watch = watcher,
        destroyed = js.defined(c => {
          c.refreshTimer match {
            case Some(timer) =>
              logger.debug("component destroyed and token refresh timer cancelled"); timer.cancel()
            case None => logger.debug("component destroyed but not timer was found")
          }
        }),
        created = js.defined(c => {
          import org.scalajs.dom.ext.LocalStorage
          import com.sludg.model.Models._
          import com.sludg.model.SettingsModelsSerializers._

          LoadingFuture.withLoading(
            loaderEventBus,
            Helper
              .tokenValidator(security, apiCalls, token)
              .map({
                case t @ Some(validToken) => {
                  c.token = t
                  val configurationNameOpt = Json
                    .parse(JSON.stringify(c.$route.asInstanceOf[js.Dynamic].params.settingName))
                    .validate[ConfigurationType]
                    .asOpt
                  c.configurationName = configurationNameOpt.map(_.formattedConfiguration)
                  // Getting Configuration from a list of configurations
                  if (configurationNameOpt.isDefined) {
                    val configurationType = configurationNameOpt.get
                    SettingsUtil
                      .getConfigurationOptionsAndSelection(
                        configurationType,
                        Api(apiCalls, validToken)
                      )(validToken)
                      .map(config => {
                        // Assigning config
                        c.configuration = config
                      })
                  } else {
                    c.configuration = None
                  }
                  c.refreshTimer = Some(
                    TokenUtil.tokenRefresher(
                      validToken.tokenString,
                      (newToken: Option[SludgToken]) => {
                        c.token = newToken
                      },
                      security
                    )
                  )
                }
                case None => security.logout()
              })
          )
        }),
        components = js.Dynamic.literal(
          "VtslApp" -> VTSLApp.createApp(
            "Salesforce-Phone",
            apiCalls,
            loaderEventBus,
            otherApps,
            Some(buildinfo.sbtinfo.version),
            None,
            _ => {
              Sforce.opencti.disableClickToDial(CallBackArgument(e => {
                if (e.success) {
                  logger.info("Successfully disabled click to dial")
                } else {
                  logger.debug("Failed to disable click to dial")
                }
              }))
              security.logout()
            },
            true
          )
        ),
        templateOrRender = Right((component, renderer) => {
          appRenderer(
            RenderOptions(
              props = Some(
                new VtslAppProps(
                  spacedToolbar = true,
                  vContentIsFluid = false,
                  temporaryDrawer = true
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(e => {
                      vLayout(
                        vFlex(),
                        vFlex(
                          if (component.configurationName.isDefined) {
                            s"${component.configurationName.get}"
                          } else nothing
                        ),
                        vFlex()
                      ).render(renderer)
                    })
                  override val default: js.UndefOr[js.Function1[Option[Any], VNode]] =
                    js.defined(tenantOpt => {
                      div(
                        if (component.configuration.isDefined) {
                          SettingsPageRenderFunctions.renderConfiguration(
                            component,
                            component.configuration.map(_.asInstanceOf[Configuration[Any]]).get
                          )
                        } else nothing
                      ).render(renderer)
                    })
                  override val toolbarLeft: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(t => {
                      vToolbarSideIcon(
                        vIcon("arrow_back"),
                        click(_ => {
                          // Save selection in Api / Local storage
                          component.token match {
                            case Some(token) => {
                              component.configuration.get.saveConfig(Api(apiCalls, token))
                              component.$router.push("/settings")
                              component.configuration = None
                            }
                            case None => component.$router.push("/settings")
                          }
                        })
                      ).render(renderer)
                    })
                }
              )
            )
          ).render(renderer)
        })
      )
  }
}

class SettingDisplayerWatcher() extends js.Object {

  /** Selection Data watcher is used to get value from the a Configurations inputHandler and then update the configuration accordingly.
    *
    * @param old
    * @param preivous
    */
  def selectionData(old: Option[Any], preivous: Option[Any]) = {
    if (js.isUndefined(old)) {} else {
      val component = this.asInstanceOf[SettingDisplayer.SettingDisplayerComponent]
      import com.sludg.model.SettingsModels.Configuration._
      // needs the type info to make copy and assign configuration selection to be displayed
      component.configuration = component.configuration.map({
        case f: FieldLogging =>
          f.copy(selection = f.selection.copy(selected = old.get.asInstanceOf[List[SelectOption]]))
        case c: ClickToCallDevice =>
          c.copy(selection = c.selection.copy(selected = Some(old.get.asInstanceOf[UserPhone])))
      })
    }
  }
}
class SettingDisplayerData extends js.Object {
  var configurationName: Option[String] = None
  var selectionData: Option[Any] = None
  var configuration: Option[Configuration[_]] = None
  var token: Option[SludgToken] = None
  var refreshTimer: Option[Timer] = None
}

