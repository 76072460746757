package com.sludg.client.components.callpanel

import java.time.Duration
import org.scalajs.dom.ext.LocalStorage
import com.sludg.model.Models._
import play.api.libs.json.Json
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.client.components.TickerProps
import cats.instances.future.catsStdInstancesForFuture
import cats.data.EitherT
import scala.concurrent.Future
import com.sludg.client.components.callpanel.CallPanel.{CallPanelComponent,ticker}
import com.sludg.client.pages.main.PhonePage.ListRenderObject
import com.sludg.client.pages.main.{Button,DisplayMessageType,PhonePage,SnackBarMessage}
import com.sludg.models.CallControlModels._
import com.sludg.salesforce.ScreenPopParam.SObjectParam
import com.sludg.salesforce._
import com.sludg.services.{ApiCalls, PhoneApi}
import com.sludg.vue.RenderHelpers.{br, div, nothing, p, span, h3}
import com.sludg.vuetify.components.grid.VGridProps
import monix.execution.Scheduler.Implicits.global
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.model.Models.SelectOption
import scala.scalajs.js
import scala.scalajs.js.{JSON, |}
import com.sludg.client.OpenCtiUtil
import com.sludg.salesforce.SFAccount.SFAccountType
import org.scalajs.dom.raw.Event

object CallPanelUtil {
    
     def sfContactDisplayer(user: SFContact, component: CallPanelComponent) = {
                div(
                p(
                  span("Name: "),
                  span(
                    s"${user.Name.getOrElse("n/a")}",
                    RenderOptions(
                      props = Some(
                        VButtonProps(
                          block = Some(true)
                        )
                      ),
                      `class` = List(
                        Left("blue--text darken-3")
                      ),
                      style = Some(
                        js.Dynamic.literal(
                          "text-decoration" -> "underline",
                          "cursor" -> "pointer"
                        )
                      ),
                      on = Some(
                        EventBindings(
                          click = js.defined(_ => {
                            if (component.currentCall.sfContact.isDefined) {
                              val sfContact =
                                component.currentCall.sfContact
                              if (sfContact.get.Name.isDefined) {
                                CallPanel.logger.debug("Defined")
                                CallPanel.logger.debug(
                                  sfContact.toString
                                )
                                OpenCtiUtil.screenPop(
                                  SObjectParam(
                                    sfContact.get.id,
                                    Some(CallBackArgument(e => {
                                      if (e.success) {
                                        CallPanel.logger.debug("success")
                                      } else {
                                        CallPanel.logger.debug("fail")
                                        CallPanel.logger.debug(
                                          JSON.stringify(e)
                                        )
                                      }
                                    }))
                                  )
                                )
                              } else {
                                CallPanel.logger.debug("no name")
                                // TODO this call Type needs to be based on call direction
                                OpenCtiUtil.searchAndScreenPop(
                                  user.Phone.get,
                                  Sforce.opencti.CALL_TYPE.INBOUND,
                                  false,
                                  Some(
                                    js.Dynamic
                                      .literal(
                                        "Phone" -> user.Phone.get
                                      )
                                      .asInstanceOf[DefaultFieldsValue]
                                  )
                                )(e => ())
                              }
                            } else {
                              CallPanel.logger.debug("default")
                              OpenCtiUtil.searchAndScreenPop(
                                user.Phone.get,
                                Sforce.opencti.CALL_TYPE.INBOUND,
                                false
                              )(e => ())
                            }
                          })
                        )
                      )
                    )
                  ),
                  br,
                  if (user.RelatedAccountName.isDefined) {
                    Seq(
                      span("Account: "),
                      span(
                        s"${user.RelatedAccountName.get}",
                        RenderOptions(
                          `class` = List(
                            Left("blue--text darken-3")
                          ),
                          style = Some(
                            js.Dynamic.literal(
                              "text-decoration" -> "underline",
                              "cursor" -> "pointer"
                            )
                          ),
                          on = Some(
                            EventBindings(
                              click = js.defined(_ => {
                                CallPanel.logger.debug("default")
                                val accountName: String =
                                  user.RelatedAccountName.get
                                OpenCtiUtil.searchAndPopAccount(accountName, user)
                              })
                            )
                          )
                        )
                      )
                    )
                  } else nothing
                )
              )
              }

    def noContactFoundDisplay(from: String, component: CallPanelComponent) = {
      p(
        span(
          "Create New",
          RenderOptions(
            props = Some(
              VButtonProps(
                block = Some(true)
              )
            ),
            `class` = List(
              Left("blue--text darken-3")
            ),
            style = Some(
              js.Dynamic.literal(
                "text-decoration" -> "underline",
                "cursor" -> "pointer"
              )
            ),
            on = Some(
              EventBindings(
                click = js.defined(_ => {
                  CallPanel.logger.debug("default")
                  OpenCtiUtil.searchAndScreenPop(
                    component.currentCall.from,
                    component.currentCall.callType,
                    false,
                     Some(js.Dynamic.literal(
                        "Phone" -> component.currentCall.from
                    ).asInstanceOf[DefaultFieldsValue])
                  )(e => ())
                })
              )
            )
          )
        )
      )
    }

    def statusDisplayer(status: String, color: String) = {
      p(status)(
        RenderOptions(
          `class` = List(
            Left("text-xs-center"),
            Left("text-md-center"),
            Left("text-lg-center"),
            Left("text-xs-center"),
            Left(color)
          )
        )
      )
    }

    def displayTicker(addTicker: Boolean) = {
      ticker(
        RenderOptions(
          props = Some(
            TickerProps(
              Some(0)
            )
          ),
          `class` = List(
            Left("text-xs-center"),
            Left("text-md-center"),
            Left("text-lg-center"),
            Left("text-xs-center")
          )
        )
      )
    }
   
}