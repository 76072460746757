package com.sludg.model

import com.sludg.models.Config._
import play.api.libs.json.{Format, Json}
import scala.scalajs.js._
import scala.scalajs.js
import scala.scalajs.js.annotation.JSExportAll
import com.sludg.util.models.SilhouetteModels.UserPhone
import com.sludg.vue.VueComponent
import com.sludg.vue.TemplatingHelpers.NodeRenderer
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VNode
import com.sludg.models.CallControlModels.CallEvent
import com.sludg.salesforce.{CallType,SFContact}
import com.sludg.vuetify.components.grid.VGridPropValues.DisplayType
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import com.sludg.model.SettingsModels.ConfigurationType.LogObject
import com.sludg.model.SettingsModels.ConfigurationType.ClickToCallDeviceSelect
import com.sludg.client.pages.settings.SettingsUtil
import cats.implicits._
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import cats.data._
import cats.instances.future.catsStdInstancesForFuture
import com.sludg.vue.ScopedSlots
import com.sludg.vue.VueProps
import com.sludg.vue.EventBindings
import com.sludg.services.ApiCalls
import org.scalajs.dom.ext.Storage
import org.scalajs.dom.ext.LocalStorage
import java.rmi.server.LoaderHandler
import com.sludg.auth0.SludgToken
import com.sludg.vuetify.components.VComboboxProps
import com.sludg.model.Models.Api
import com.sludg.model.Models.SelectOption
import com.sludg.model.SettingsModels.ConfigurationType.LogCaseComment

object SettingsModels {
      
  /**
   * Used to decide the display type of a setting selection
   * TODO this could be the selection type
   */
  trait ConfigurationDisplay
  case object PickList extends ConfigurationDisplay
  case object MultiSelect extends ConfigurationDisplay
  case object Text extends ConfigurationDisplay
  case object Option extends ConfigurationDisplay
  case object PhoneSetting extends ConfigurationDisplay
  case object Selectable extends ConfigurationDisplay

  /**
   * This configuration type is a mapping to Configuration trait used to save selection
   */
  trait ConfigurationType {
    val formattedConfiguration: String
  }

  /** These map the implementations of the configuration type */
  object ConfigurationType {
    case object LogObject extends ConfigurationType {
      override val formattedConfiguration: String = "Log Object"
    }
    case object LogField extends ConfigurationType {
      override val formattedConfiguration: String = "Log Field"
    }
    case object ClickToCallDeviceSelect extends ConfigurationType {
      override val formattedConfiguration: String = "Click-To-Call Device Select"
    }
     case object LogCaseComment extends ConfigurationType {
      override val formattedConfiguration: String = "Log Case Comment"
    }
  }

  /** The trait is used to define selection objects that are saved for a configuration in local storage */
  trait SelectionType {
    val configurationType: ConfigurationType // Reference to use to figure out which configuration it belongs to.
  }
   
   /**
   * This defines a selection type that stores a list of selected types
   */
  case class ListSelection[A](
    configurationType: ConfigurationType,
    selected: List[A]
  ) extends SelectionType

  
  /**
   * This defines a selection type that stores a single type
   */
  case class Single[A](
    configurationType: ConfigurationType,
    selected: Option[A]
  ) extends SelectionType


  /**
   * Contract for configuration that defines how particular selection of a configuration is saved
   */
  trait Configuration[A] {
    val configurationType: ConfigurationType
    val displayType: ConfigurationDisplay
    val options: List[A]
    val selection: SelectionType
    val name: String
    val description: String
    val itemToText: A | List[A] => String
    val itemToValue: A | List[A] => Any
    val icon: String

    /** If a configuration is a simple true/false value */
    val isBoolean: Boolean = false

    /** Defines a way to save a configuration, whether locally (LocalStorage) or to a database (ApiCalls) */
    def saveConfig: Api => Unit
    // TODO add getOptions method to load options values
  }

  /** Defines several configurations based on the configuration trait */
  object Configuration {

    /** Used for deciding what fields to include in Salesforce Activity Log */
      case class FieldLogging(
        configurationType: ConfigurationType,
        displayType: ConfigurationDisplay,
        options: List[SelectOption],
        selection: ListSelection[SelectOption],
     ) extends Configuration[SelectOption] {
       override val name: String = "Log Fields"
       override val description: String = "Select fields to add to your logging"
       override val icon: String = "add_comment"
       override val itemToText: SelectOption | List[SelectOption] => String = a => a match {
         case a: SelectOption => a.label
       }
       override val itemToValue: SelectOption | List[SelectOption] => Any = a => a match {
         case a: SelectOption => a.asInstanceOf[js.Any]
       }
       override def saveConfig: Api => Unit = api => {
         import com.sludg.model.SettingsModelsSerializers.listSelectionSelectOptionFormat
         api.localStorage.update(this.selection.configurationType.toString(), Json.toJson(this.selection)(listSelectionSelectOptionFormat).toString)
       }
     }

     /** A boolean Configuration used to decide inclusion of comment to case comments when a call is logged in salesforce */
     case class CaseComment(
       configurationType: ConfigurationType,
        displayType: ConfigurationDisplay,
        options: List[Boolean] = Nil, // Redundant
        selection: Single[Boolean],
        override val isBoolean: Boolean = true
     ) extends Configuration[Boolean] {
       override val name = "Include Case Comments"
       override val icon: String = "add_comment"
       override val description = "Selecting this will save your comments into Logs"
       override val itemToText: Boolean | List[Boolean] => String = a => ??? // Redundant
       override val itemToValue: Boolean | List[Boolean] => Any = a => ??? // Redundant
       override def saveConfig: Api => Unit = api => {
        import com.sludg.model.SettingsModelsSerializers.singleSelectionBooleanFormat
        import play.api.libs.json._
        import play.api.libs.json.Format
        import play.api.libs.functional.syntax.{unlift, _}
         api.localStorage.update(this.selection.configurationType.toString(), Json.toJson(this.selection)(singleSelectionBooleanFormat).toString())
       }
     }

     /** Used to select a device used to click-to-call functionality */
      case class ClickToCallDevice(
        configurationType: ConfigurationType,
        displayType: ConfigurationDisplay,
        options: List[UserPhone],
        selection: Single[UserPhone],
     ) extends Configuration[UserPhone] {
       override val name = "Click To Call"
       override val description = "Select your click to call device"
       override val icon: String = "call_made"
       override val itemToText: UserPhone | List[UserPhone] => String = a => a match {
         case u: UserPhone => u.userLabel
       }
       override val itemToValue: UserPhone | List[UserPhone] => Any = a => a match {
         case u: UserPhone => u.asInstanceOf[js.Any]
       }
       override def saveConfig: Api => Unit = api => {
         if (this.selection.selected.isDefined) {
         import monix.execution.Scheduler.Implicits.global

         implicit val token = api.token
          val tenantId = api.token.tenants(0)
          val extensionId = api.token.subscribers(0).ext.toInt 
         api.apiCalls.setClickToDialDevice(tenantId, extensionId, this.selection.selected.get.phoneId)
         } else {
           // TODO return an either with the error message for snackbar
         }
       }
     }
  }


  /** Overall setting type that contains multiple configurations 
   * 
   * Used for displaying in the settings menu.
  */
  sealed trait Setting {
    val name: String
    val configurations: List[Configuration[_]]
  }
  
  /** Defines setting type related to Call Logging */
  case class CallLogSettings(configurations: List[Configuration[_]]) extends Setting {
    override val name = "Call Log Settings"
  }
  
  /** Defines setting type related to click to origination device */
  case class CallOriginationSettings(configurations: List[Configuration[_]]) extends Setting {
    override val name = "Call Orgination Settings"
  }
}

object SettingsModelsSerializers {
  // Imports weren't being read on the page level for some reason
  import play.api.libs.json._
  import play.api.libs.json.JsSuccess
  import play.api.libs.functional.syntax.{unlift, _}
  import com.sludg.util.models.SilhouetteModels.UserPhone
  import com.sludg.util.models.Events
  import com.sludg.util.json.SilhouetteDeserializers._
  import com.sludg.salesforce.{CallType}
  import com.sludg.models.CallControlModels.CallEvent
  import com.sludg.model.SettingsModels._

  implicit val configTypeWrites: Writes[ConfigurationType] = Writes[ConfigurationType] {
    case x@LogObject => JsString("LogObject")
    case x@LogField => JsString("LogField")
    case x@ClickToCallDeviceSelect => JsString("ClickToCallDeviceSelect")
    case x@LogCaseComment => JsString("LogCaseComment")
    case e => JsNull
  }

  implicit val configTypeReads: Reads[ConfigurationType] = Reads[ConfigurationType] {
    case a@JsString("LogObject") => JsSuccess(LogObject)
    case a@JsString("LogField") => JsSuccess(LogField)
    case a@JsString("ClickToCallDeviceSelect") => JsSuccess(ClickToCallDeviceSelect)
    case a@JsString("LogCaseComment") => JsSuccess(LogCaseComment)
    case _ => JsError("Incorrect value for SettingType")
  }

  implicit val configTypeFormat: Format[ConfigurationType] = Format(configTypeReads, configTypeWrites)
  implicit val selectOptionFormat = Json.format[SelectOption]
  implicit val singleSelectionUserPhoneCallLogFormat = Json.format[Single[UserPhone]]
  implicit val singleSelectionBooleanFormat = Json.format[Single[Boolean]]
  implicit val singleSelectionStringCallLogFormat = Json.format[Single[String]]
  implicit val listSelectionCallLogFormat = Json.format[ListSelection[String]]
  implicit val listSelectionSelectOptionFormat = Json.format[ListSelection[SelectOption]]

}
