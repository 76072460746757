package com.sludg.client.components.callpanel

import java.time.Duration
import org.scalajs.dom.ext.LocalStorage
import com.sludg.model.Models._
import play.api.libs.json.Json
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.client.components.TickerProps
import cats.instances.future.catsStdInstancesForFuture
import cats.data.EitherT
import scala.concurrent.Future
import com.sludg.client.components.callpanel.CallPanel.{CallPanelComponent, ticker}
import com.sludg.client.pages.main.PhonePage.ListRenderObject
import com.sludg.client.pages.main.{Button, DisplayMessageType, PhonePage, SnackBarMessage}
import com.sludg.models.CallControlModels._
import com.sludg.salesforce.ScreenPopParam.SObjectParam
import com.sludg.salesforce._
import com.sludg.services.{ApiCalls, PhoneApi}
import com.sludg.vue.RenderHelpers.{br, div, nothing, p, span, h3}
import com.sludg.vuetify.components.grid.VGridProps
import monix.execution.Scheduler.Implicits.global
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.model.Models.SelectOption
import scala.scalajs.js
import scala.scalajs.js.JSConverters._
import scala.scalajs.js.{JSON, |}
import com.sludg.client.OpenCtiUtil
import com.sludg.salesforce.SFAccount.SFAccountType
import org.scalajs.dom.raw.Event

object CallPanelRenderFunctions {

  def relatedContactSelect(
      component: CallPanelComponent,
      renderer: CreateElement
  ): RenderHelpers.NodeRenderer[VueProps, EventBindings, ScopedSlots] = {
    div(
      "Multiple Records Found",
      vAutocomplete(
        RenderOptions[VAutocompleteProps[
          SFContact
        ], VAutocompleteEventBindings, VAutocompleteScopedSlots[SFContact]](
          props = Some(
            VAutocompleteProps[SFContact](
              placeholder = Some("Please Select a Contact"),
              items = Some(component.currentCall.relatedContacts),
              solo = Some(true),
              itemText = Some(
                Right(c => c.asInstanceOf[SFContact].Name.getOrElse("N/a"))
              ),
              dense = Some(true),
              itemValue = Some(Right(a => a)),
              `return-object` = Some(true)
            )
          ),
          on = Some(
            VAutocompleteEventBindings(
              input = js.defined(input => {
                if (js.isUndefined(input) && input != null) {} else {
                  val contact: SFContact = input.asInstanceOf[SFContact]
                  CallPanel.logger.debug(s"Input changed $contact")
                  component.$emit("sfContact-updated", Some(contact))
                }
              })
            )
          ),
          scopedSlots = Some(
            new VAutocompleteScopedSlots[SFContact](
              item = js.defined(props => {
                val item = props.item
                div(
                  span(s"Name: ${item.Name.get}", br),
                  if (item.RelatedAccountName.isDefined)
                    span(s"Account: ${item.RelatedAccountName.get}")
                  else nothing
                ).render(renderer)
              })
            )
          )
        )
      )
    )
  }

  def logOptionsDisplayer(
      logOptions: Map[SelectOption, List[SelectOption]],
      logSelection: Map[String, Option[SelectOption]],
      renderer: CreateElement,
      component: CallPanelComponent
  ) = {
    logOptions
      .groupBy(_._1)
      .toSeq
      .map(seqOfTuple => {
        import scalajs.js.JSConverters._
        val logOptionValue = seqOfTuple._1 // From Options
        val options = seqOfTuple._2.get(logOptionValue)
        val selection = logSelection.get(logOptionValue.value) // Selection
        val selectedValue = selection.flatten
        val selectedValueAsString = selectedValue.map(_.value).getOrElse("")
        logOptionValue.fieldType match {
          case "PICKLIST" => {
            vAutocomplete(
              RenderOptions[VAutocompleteProps[
                SelectOption
              ], VAutocompleteEventBindings, VAutocompleteScopedSlots[SelectOption]](
                props = Some(
                  js.Dynamic
                    .literal(
                      "items" -> options.map(_.toJSArray).getOrElse(js.Array()),
                      "item-text" -> ((
                          selectOption =>
                            (selectOption: Any) match {
                              case single: SelectOption => single.label
                              case _ => ""
                            }
                      ): js.Function1[js.Array[SelectOption] | SelectOption, String]),
                      "value" -> selectedValue
                        .map(_.asInstanceOf[js.Any])
                        .getOrElse(js.Dynamic.literal()),
                      "return-object" -> true,
                      "placeholder" -> s"Select ${logOptionValue.label}",
                      "clearable" -> true,
                      "dense" -> true
                    )
                    .asInstanceOf[VAutocompleteProps[SelectOption]]
                ),
                on = Some(
                  VAutocompleteEventBindings(
                    input = js.defined(e => {
                      if (js.isUndefined(e)) {
                        CallPanel.logger.info("undefined")
                        component.$emit("logOption-selected", (logOptionValue.value, None))
                      } else {
                        CallPanel.logger.info("INPUT")
                        CallPanel.logger.info(e.toString())
                        val selectOption: Option[SelectOption] = if (e != null) {
                          Some(e.asInstanceOf[SelectOption])
                        } else {
                          CallPanel.logger.info("getting none")
                          CallPanel.logger.info(e.toString())
                          None
                        }
                        component.$emit("logOption-selected", (logOptionValue.value, selectOption))
                      }
                    })
                  )
                )
              )
            )
            // .render(renderer)
          }
          case "TEXTAREA" =>
            VTextArea.vTextArea(
              RenderOptions(
                props = Some(
                  js.Dynamic
                    .literal(
                      "value" -> selectedValueAsString,
                      "placeholder" -> s"Add ${logOptionValue.label}..",
                      "clearable" -> true,
                      "dense" -> true,
                      "auto-grow" -> true
                      // "height" -> "30"
                    )
                    .asInstanceOf[VTextAreaProps]
                )
                // VTextAreaProps(
                //   // box = Some(true),
                //   // solo = Some(true),
                //   dense = Some(true),
                //   clearable = Some(true),
                //   value = selectedValue.map(_.value),
                //   `no-resize` = Some(true),
                // )
                ,
                on = Some(
                  EventBindings(
                    input = js.defined(description => {
                      if (js.isUndefined(description) && description != null) {
                        CallPanel.logger.debug("undefined description")
                      } else {
                        val selectOptionDescription = Some(
                          SelectOption(
                            description.toString,
                            logOptionValue.label,
                            logOptionValue.fieldType
                          )
                        )
                        component.$emit(
                          "logOption-selected",
                          (logOptionValue.value, selectOptionDescription)
                        )
                      }
                    })
                  )
                )
              )
            )
          // .render(renderer)
          case _ => div(nothing)
          // .render(renderer)
        }
      })
  }

  def sfObjectSelector(
      component: CallPanelComponent,
      renderer: CreateElement
  ) = {
    Seq(
      vCombobox[SFObject](
        RenderOptions(
          props = Some(
            VComboboxProps[SFObject](
              items = Some(component.currentCall.selectableSFObjects),
              clearable = Some(true),
              placeholder = Some("Select Related"),
              dense = Some(true),
              // solo = Some(true),
              `item-value` = Some(Right(r => r)),
              value = component.currentCall.selectedSfObject.map(s => Right(s))
            )
          ),
          on = Some(
            js.Dynamic
              .literal(
                "input" -> ((input => {
                  if (js.isUndefined(input)) {
                    CallPanel.logger.info("SFObject input not defined")
                    component.$emit("sfObject-updated", None)
                  } else {
                    val sfObject = input.get
                    OpenCtiUtil.screenPop(
                      SObjectParam(
                        sfObject.id,
                        Some(CallBackArgument(e => {
                          if (e.success) {
                            component.$root.$emit(
                              "display-message",
                              SnackBarMessage(
                                s"Opening: ${sfObject.RecordType}",
                                messageType = DisplayMessageType.Success
                              )
                            )
                          } else {
                            component.$root.$emit(
                              "display-message",
                              SnackBarMessage(
                                s"Error Logging Call: ${e.errors.head.code} - ${e.errors.head.description} ",
                                messageType = DisplayMessageType.Error
                              )
                            )
                          }
                        }))
                      )
                    )
                    component.$emit("sfObject-updated", Some(sfObject))
                  }
                }): js.Function1[js.UndefOr[SFObject], Unit])
              )
              .asInstanceOf[VAutocompleteEventBindings]
          ),
          scopedSlots = Some(new VComboboxScopedSlots[SFObject] {
            override val item: js.UndefOr[js.Function1[VComboboxItemSlotScope[
              SFObject
            ], VNode | js.Array[VNode]]] = js.defined(props => {
              val item = props.item
              item match {
                case singleCase: SFCase => {
                  div(
                    if (singleCase.CaseNumber.isDefined)
                      span(s"Case Number: ${singleCase.CaseNumber.get}", br)
                    else nothing,
                    if (singleCase.Subject.isDefined)
                      span(
                        s"Subject: ${singleCase.Subject.map(_.substring(0, 30) + "...").get}"
                      )
                    else nothing
                  ).render(renderer)
                }
                case opportunity: SFOpportunity => {
                  div(
                    span(s"Name: ${opportunity.Name}", br),
                    if (opportunity.StageName.isDefined)
                      span(
                        s"Stage: ${opportunity.StageName.get}"
                      )
                    else nothing
                  ).render(renderer)
                }
                case lead: SFLead => {
                  div(
                    span(s"Name: ${lead.Name.getOrElse("")}", br),
                    if (lead.Status.isDefined)
                      span(s"Status: ${lead.Status.get}")
                    else div("Lead")
                  ).render(renderer)
                }
                case notConsidered => {
                  div(notConsidered.id).render(renderer)
                }
              }
            })
            override val selection: js.UndefOr[js.Function1[
              VComboboxSelectionSlotScope[SFObject],
              VNode | js.Array[VNode]
            ]] = js.defined(props => {
              val item = props.item
              item match {
                case singleCase: SFCase => {
                  span(s"Case Number: ${singleCase.CaseNumber.get}")
                    .render(renderer)
                }
                case opportunity: SFOpportunity => {
                  p(
                    span(s"Opportunity: ${opportunity.Name}  ")
                  ).render(renderer)
                }
                case lead: SFLead => {
                  p(
                    span(s"Lead: ${lead.Name}  ")
                  ).render(renderer)
                }
                case notConsidered => {
                  div(notConsidered.id).render(renderer)
                }
              }
            })
          })
        )
      )
      // TODO create new cases for a contact
      // ,
      // vMenu(
      //   RenderHelpers.template(
      //     vButton(
      //       vIcon(
      //         "add_circle_outline",
      //         RenderOptions(
      //           slot = Some("activator")
      //         )
      //       )
      //     )
      //   ),
      //   vList(
      //     component.allowedObjects.map(objectName => {
      //       VListItem.vListItem(
      //         VListItemTitle.vListItemTitle(s"$objectName"),
      //         RenderOptions(
      //           on = Some(
      //             EventBindings(
      //               click = js.defined(_ => {
      //                 OpenCtiUtil.screenPop(
      //                   ScreenPopParam.NewRecordModel(
      //                     objectName,
      //                     Some(CallBackArgument(e => {
      //                       if (e.success) {
      //                         CallPanel.logger
      //                           .debug("Creating a new " + objectName)
      //                       } else {
      //                         CallPanel.logger.debug("Failed to create")
      //                       }
      //                     }))
      //                   )
      //                 )
      //               })
      //             )
      //           )
      //         )
      //       )
      //     })
      //   ),
      //   RenderOptions(
      //     props = Some(
      //       VMenuProps(
      //         closeOnClick = Some(true),
      //         closeOnContentClick = Some(false),
      //         offsetX = Some(true),
      //         `full-width` = Some(true),
      //         top = Some(true)
      //       )
      //     )
      //   )
      // )
    )
  }

  // Why not make this a component mannn
  def callCard[A <: SFObject](
      component: CallPanelComponent,
      renderer: CreateElement,
      status: String,
      color: String,
      contact: Option[SFContact],
      leftButton: Option[Button] = None,
      rightButton: Option[Button] = None,
      listItem: Option[ListRenderObject[A]] = None,
      addTicker: Boolean = false,
      duration: Option[String] = None,
      addNote: Boolean = false,
      isCallLogSettings: Boolean = false
  ) = {
    CallPanel.logger.info("Rendering Call Card with")
    CallPanel.logger.info(s"status: $status")
    CallPanel.logger.info(s"color: $color")
    CallPanel.logger.info(s"contact: $contact")
    CallPanel.logger.info(s"addTicker: $addTicker")
    CallPanel.logger.info(s"duration: $duration")
    CallPanel.logger.info(s"addNote: $addNote")
    CallPanel.logger.info(s"isCallLogSettings: $isCallLogSettings")
    CallPanel.logger.info(s"Left button defined: $leftButton.isDefined")
    CallPanel.logger.info(s"Right button defined: $rightButton.isDefined")

    div(
      vCard(
        CallPanelUtil.statusDisplayer(status, color),
        if (addTicker) CallPanelUtil.displayTicker(addTicker) else nothing,
        if (
          component.currentCall.relatedContacts.size > 1 && component.currentCall.sfContact.isEmpty
        ) {
          relatedContactSelect(component, renderer)
        } else {
          vLayout(
            RenderOptions(
              props = Some(
                VGridProps(
                  justification = Some(`justify-center`),
                  alignment = Some(`align-center`)
                )
              )
            ),
            if (component.currentCall.relatedContacts.size > 1) {
              CallPanel.logger.debug(
                "related Contacts more than 1 so rendering back button"
              )
              Seq(
                vIcon(
                  "arrow_back",
                  RenderOptions(
                    on = Some(
                      EventBindings(
                        click = js.defined(_ => {
                          component.$emit("sfContact-updated", None)
                        })
                      )
                    )
                  )
                ),
                vSpacer()
              )
            } else {
              vSpacer()
            },
            if (contact.isDefined) {
              val user = contact.get
              Seq(
                CallPanelUtil.sfContactDisplayer(user, component),
                vSpacer()
              )
            } else {
              Seq(
                CallPanelUtil.noContactFoundDisplay(component.currentCall.from, component),
                vSpacer()
              )
            }
          )
        },
        // Call Logging Settings
        if (
          component.logOptions.isDefined && component.logOptions.get.nonEmpty && component.currentCall.sfContact.isDefined
        ) {
          CallPanel.logger.info(
            "Map not empty"
          )
          CallPanel.logger.info(
            component.logOptions.toString()
          )
          Seq(
            vContainer(
              RenderOptions(
                `class` = List(Left("scroll-y")),
                style = Some(js.Dynamic.literal("max-height" -> "200px"))
              ),
              if (listItem.isDefined) {
                CallPanel.logger.info("LIST IS DEFINED")
                val item = listItem.get
                val itemSize = item.listItems.size
                if (itemSize < 1) {
                  CallPanel.logger.info("LIST IS LESS THAN 1")
                  nothing
                } else {
                  div(
                    sfObjectSelector(component, renderer)
                  )
                }
              } else {
                CallPanel.logger.info("LIST IS NOT DEFINED")
                nothing
              }, {
                val nodeArray = logOptionsDisplayer(
                  component.logOptions.get,
                  component.currentCall.selectedLogOptions.toMap,
                  renderer,
                  component
                )
                nodeArray.map(d => div(d)).toSeq
              }
            )
          )
        } else {
          CallPanel.logger.info("Map is empty")
          nothing
        },
        if (leftButton.isDefined || rightButton.isDefined) {
          vCardActions(
            if (leftButton.isDefined)
              vButton(
                leftButton.get.name,
                RenderOptions(
                  props = Some(
                    VButtonProps(
                      block = Some(true)
                    )
                  ),
                  on = Some(
                    EventBindings(
                      click = leftButton.get.oncClick
                    )
                  )
                )
              )
            else nothing,
            vSpacer,
            // Logging Call
            if (rightButton.isDefined)
              vButton(
                rightButton.get.name,
                RenderOptions(
                  on = Some(
                    EventBindings(
                      click = rightButton.get.oncClick
                    )
                  )
                )
              )
            else nothing
          )
        } else {
          CallPanel.logger.info("Buttons not defined")
          nothing
        }
      )
    )
  }

  def discardButton(
      callEvent: CallEvent
  )(implicit component: CallPanelComponent): Button =
    Button(
      "Discard",
      js.defined(e => {
        component.$emit("call-discarded", Some(component.currentCall))
      })
    )

  def receivedCard(
      callReceived: CallReceived,
      component: CallPanelComponent,
      renderer: CreateElement,
      apiCalls: PhoneApi,
      sfContact: Option[SFContact]
  ) = {
    callCard[SFObject](
      component,
      renderer,
      "Incoming Call",
      "yellow",
      sfContact,
      listItem = Some(
        ListRenderObject[SFObject](
          component.currentCall.relatedSfObjects,
          c => c,
          c => c.id
        )
      ),
      rightButton = Some(discardButton(callReceived)(component))
    )
  }

  def callDialled(
      callDialed: CallDialed,
      component: CallPanelComponent,
      renderer: CreateElement,
      apiCalls: PhoneApi,
      sfContact: Option[SFContact]
  ) = {
    callCard(
      component,
      renderer,
      "Dialing...",
      "yellow",
      sfContact,
      listItem = Some(
        ListRenderObject[SFObject](
          component.currentCall.relatedSfObjects,
          c => c,
          c => c.id
        )
      ),
      rightButton = Some(discardButton(callDialed)(component))
    )
  }

  def errorCard(
      callError: CallError,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) = {
    callCard[SFObject](
      component,
      renderer,
      s"Call Error: ${callError.error}",
      "red",
      None,
      rightButton = Some(discardButton(callError)(component))
    )
  }

  def ringBackCard(
      callRingBack: CallRingBack,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) =
    callCard[SFObject](
      component,
      renderer,
      "Ringing",
      "blue",
      sfContact,
      rightButton = Some(discardButton(callRingBack)(component))
    )

  def ringingCard(
      callRinging: CallRinging,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) =
    callCard[SFObject](
      component,
      renderer,
      "Incoming Call: Ringing",
      "blue",
      sfContact,
      rightButton = Some(discardButton(callRinging)(component))
    )

  def endedCard(
      callEnd: CallEnd,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) = {

    callCard[SFObject](
      component,
      renderer,
      s"Call Ended",
      "red darken-2",
      sfContact,
      leftButton = Some(
        Button(
          "Wrap up (Log)",
          js.defined(e => {
            component
              .logCall(
                contact = sfContact,
                accountId = None,
                sfObject = component.currentCall.selectedSfObject,
                selectedLogOptions = component.currentCall.selectedLogOptions.toMap
              )
              .value
              .map(e => {
                e.fold(
                  e => "",
                  c => {
                    component.$emit("call-wrapped", callEnd.Id)
                    c.recordId
                  }
                )
              })
          })
        )
      ),
      rightButton = Some(discardButton(callEnd)(component)),
      listItem = Some(
        PhonePage.ListRenderObject[SFObject](
          component.currentCall.relatedSfObjects,
          c => c.id,
          e => e.id
        )
      ),
      addNote = true,
      isCallLogSettings = true
    )
  }

  def transferCard(
      callTransfer: CallTransfer,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) =
    callCard[SFObject](
      component,
      renderer,
      s"Incoming Transfer for: ${component.currentCall.from}",
      "blue",
      sfContact,
      leftButton = Some(
        Button(
          "Wrap up Call",
          js.defined(e => {
            component
              .logCall(
                contact = sfContact,
                accountId = None,
                sfObject = component.currentCall.selectedSfObject,
                selectedLogOptions = component.currentCall.selectedLogOptions.toMap
              )
              .value
              .map(e => {
                e.fold(
                  e => "",
                  c => {
                    component.$emit("call-wrapped", callTransfer.Id)
                    c.recordId
                  }
                )
              })

          })
        )
      ),
      listItem = Some(
        PhonePage.ListRenderObject[SFObject](
          component.currentCall.relatedSfObjects,
          c => c.id,
          e => e.id
        )
      ),
      addNote = true,
      isCallLogSettings = true
    )

  def answeredCard(
      callAnswered: CallAnswered,
      component: CallPanelComponent,
      renderer: CreateElement,
      apiCalls: PhoneApi,
      sfContact: Option[SFContact]
  ) = {
    Seq(
      callCard[SFObject](
        component,
        renderer,
        "Call Established",
        "green",
        sfContact,
        listItem = Some(
          ListRenderObject[SFObject](
            component.currentCall.relatedSfObjects,
            c => c,
            c => c.id
          )
        ),
        leftButton = Some(
          Button(
            "Wrap up (Log)",
            js.defined(e => {
              component
                .logCall(
                  contact = sfContact,
                  accountId = None,
                  sfObject = component.currentCall.selectedSfObject,
                  selectedLogOptions = component.currentCall.selectedLogOptions.toMap
                )
                .value
                .map(e => {
                  e.fold(
                    e => "",
                    c => {
                      component.$emit("call-wrapped", callAnswered.Id)
                      c.recordId
                    }
                  )
                })
            })
          )
        ),
        rightButton = Some(discardButton(callAnswered)(component)),
        addTicker = false,
        addNote = true,
        isCallLogSettings = true
      )
    )
  }

  def resumeCard(
      callResume: CallResume,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) = {
    Seq(
      callCard[SFObject](
        component,
        renderer,
        "Call Established: Resumed",
        "green",
        sfContact,
        listItem = Some(
          ListRenderObject[SFObject](
            component.currentCall.relatedSfObjects,
            c => c,
            c => c.id
          )
        ),
        rightButton = Some(discardButton(callResume)(component)),
        addTicker = false,
        addNote = true,
        isCallLogSettings = true
      )
    )
  }

  def holdCard(
      callHold: CallHold,
      component: CallPanelComponent,
      renderer: CreateElement,
      sfContact: Option[SFContact]
  ) = {
    Seq(
      callCard[SFObject](
        component,
        renderer,
        "Call Held",
        "yellow",
        sfContact,
        listItem = Some(
          ListRenderObject[SFObject](
            component.currentCall.relatedSfObjects,
            c => c,
            c => c.id
          )
        ),
        rightButton = Some(discardButton(callHold)(component)),
        addTicker = false,
        addNote = true,
        isCallLogSettings = true
      )
    )
  }
}

trait ItemScopedSlot[A <: SFObject] extends js.Object {
  val parent: CallPanelComponent
  val item: A
  val tile: js.Dynamic
}

trait SelectionScopedSlotProps[A <: SFObject] extends VueProps {
  val parent: CallPanelComponent
  val item: A
  val index: Int
  val selected: Boolean
  val disabled: Boolean
}
