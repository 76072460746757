package com.sludg.client.pages.main

import cats.effect._
import cats.implicits._
import retry._

import scala.concurrent.duration._
import scala.util.control.NoStackTrace
import com.sludg.model.SettingsModels._
import com.sludg.salesforce._
import com.sludg.services.WebsocketMessage
import com.sludg.services.PhoneApi
import com.sludg.models.Status.PhoneStatus
import com.sludg.models.CallControlModels._
import com.sludg.client.OpenCtiUtil
import com.sludg.vuetify.components.VComboboxProps
import com.sludg.salesforce.SFCase.SFCaseType
import com.sludg.salesforce.SFLead.SFLeadType
import com.sludg.salesforce.SFOpportunity.SFOpportunityType
import com.sludg.salesforce.SFObject.SFOtherObjectType
import com.sludg.auth0.SludgToken
import com.sludg.model.Models.SelectOption
import com.sludg.model.Models
import scala.concurrent.Future
import com.sludg.client.pages.settings.SettingsUtil
import com.sludg.client.pages.main.PhonePage._
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import org.scalajs.dom.ext.LocalStorage
import scala.scalajs.js.JSON
import scala.scalajs.js
import com.sludg.model.Models.CallData
import cats.data.EitherT
import scala.concurrent.Promise
import com.sludg.salesforce.SFContact.SFContactType
import com.sludg.services.Websockets
import scala.concurrent.ExecutionContext
import com.sludg.salesforce.{CallType, SFContact}

object PhonePageUtil {

  import monix.execution.Scheduler.Implicits.global
  case class UnexpectedClosure(message: String) extends NoStackTrace
  case class ConnectionError(message: String) extends NoStackTrace

  def retryReportingToSnackbarOnError[A](
      component: PhonePage.PhonePageComponent,
      messagePrefix: String,
      target: IO[A]
  )(implicit sleep: Sleep[IO]) = {
    retryingOnAllErrors(
      RetryPolicies.capDelay(10.seconds, RetryPolicies.exponentialBackoff[IO](1.second)),
      (e: Throwable, r) => {

        PhonePage.logger.error(e)("An error occurred.")

        val extraText = e match {
          case ConnectionError(message) => " " + message.toString + "."
          case UnexpectedClosure(message) => " " + message.toString + "."
          case _ => ""
        }

        IO {
          displayMessage(
            component,
            SnackBarMessage(
              s"$messagePrefix.$extraText ${r.upcomingDelay.fold(
                "Giving up due to repeated failure. Please check your internet connection and refresh the page."
              )(d => s"Will try again in ${d.toSeconds} seconds.")}",
              DisplayMessageType.Error
            )
          )
        }
      }
    )(target)
  }

  def enableClickToDial(implicit cs: ContextShift[IO]): IO[Unit] = {
    IO.async[Unit] { cb =>
      Sforce.opencti.enableClickToDial(
        EnableClickToDialArguments(
          CallBackArgument(e => {
            if (e.success) {
              PhonePage.logger.info("Click to dial is enabled")
              // Available
              cb(Right(()))
            } else {
              // SalesforceError
              PhonePage.logger.error("Click To Dial couldn't be enabled")
              cb(Left(ConnectionError("Could not enable click to dial")))
            }
          })
        )
      )
    }.guarantee(IO.shift)
  }

  def initialiseClickToCallCallback(
      component: PhonePage.PhonePageComponent,
      api: PhoneApi,
      sludgToken: SludgToken
  ) =
    IO {
      // Salesforce On Click To dial Handler
      Sforce.opencti.onClickToDial(SalesforceListener[ClickToDialPayload](f => {
        PhonePage.logger.info("About to click to dial")
        OpenCtiUtil.makeSoftPhoneVisibleIfClosed()
        val callingTo: String = f.number
        component.token.fold(
          displayMessage(
            component,
            SnackBarMessage(
              s"Cannot click to dial due to lack of authentication token",
              DisplayMessageType.Error
            )
          )
        )(_ => {
          component.makeCall(callingTo.filterNot(_.isWhitespace), api, true)(sludgToken)
        })
      }))
    }

  def websocketProcessor(
      component: PhonePage.PhonePageComponent
  ): WebsocketMessage[CallEvent] => IO[Unit] = {
    case WebsocketMessage.Closed =>
      IO { component.phoneStatus = Some(PhoneStatus.NotConnected) } *>
        IO.raiseError(
          UnexpectedClosure("The connection was closed unexpectedly")
        )
    case WebsocketMessage.Open =>
      IO { component.phoneStatus = Some(PhoneStatus.Connected) } *>
        IO {
          displayMessage(
            component,
            SnackBarMessage("Connected", DisplayMessageType.Success)
          )
        }
    case WebsocketMessage.Error(e) =>
      val message = s"A connection error has occurred."
      IO { component.phoneStatus = Some(PhoneStatus.Error(message)) } *>
        IO.raiseError(ConnectionError(message))
    case WebsocketMessage.Message(callDialed: CallDialed) =>
      IO {
        PhonePage.logger.debug(s"CallDialed Event: $callDialed")
        updateQueue(component, callDialed.Id, callDialed, true)
      }
    case WebsocketMessage.Message(callTransfer: CallTransfer) =>
      IO {
        PhonePage.logger.debug(s"CallTransfer Event: $callTransfer")
        updateQueue(component, callTransfer.Id, callTransfer, transferred = true)
      }
    case WebsocketMessage.Message(callEvent: CallEvent) =>
      IO {
        updateQueue(component, callEvent.Id, callEvent)
        PhonePage.logger.debug(s"Call Event: $callEvent")
      }
  }

  def removeFromQueue(silhouetteId: String, component: PhonePageComponent) = {
    PhonePage.logger.info("removing from queue")
    PhonePage.logger.info(s"removingFromQueue CQ Pre: ${js.isUndefined(component.callQueue)}")
    component.callQueue = component.callQueue.filterNot(c => c.Id == silhouetteId)
    PhonePage.logger.info(s"removingFromQueue CQ Post: ${js.isUndefined(component.callQueue)}")
  }

  def displayMessage(component: PhonePageComponent, snackbarMessage: SnackBarMessage) = {
    component.snackBarMessage = Some(snackbarMessage)
    component.displaySnackBar = true
  }

  /** Utility Method to update CallData in in a call queue `List[CallData]` based on CallData Id
    *
    * @param currentCallQueue
    * @param updatedCallData
    * @return
    */
  def updateCallDataInQueue(
      currentCallQueue: List[CallData],
      updatedCallData: CallData
  ): List[CallData] = {
    currentCallQueue.find(c => c.Id == updatedCallData.Id) match {
      case Some(callData) =>
        val index: Int = currentCallQueue.indexOf(callData)
        currentCallQueue.patch(index, List(updatedCallData), 1)
      case None =>
        currentCallQueue
    }
  }

  // Gets LogOptions from Salesforce based on the CallLog Configurations Selected in Settings
  def getLogOptions(
      c: PhonePageComponent
  ): Future[Map[SelectOption, List[com.sludg.model.Models.SelectOption]]] = {
    // Load Log Options Fields from Local Storage
    import com.sludg.model.SettingsModelsSerializers._
    PhonePage.logger.info("Getting log options Inside")

    PhonePage.logger.info("LOGGING OPTIONS")
    val logFieldSetting: Option[ListSelection[SelectOption]] =
      SettingsUtil.getSelection[ListSelection[SelectOption]](LogField, LocalStorage)
    PhonePage.logger.info(logFieldSetting.toString)
    if (logFieldSetting.isDefined) {
      val logFieldOptions = logFieldSetting.get.selected
      val getOptions: List[scala.concurrent.Future[Either[List[
        com.sludg.salesforce.ErrorMessage
      ], (SelectOption, List[com.sludg.model.Models.SelectOption])]]] =
        logFieldOptions.map(selOpt => {
          PhonePage.logger.info("Forming Query")

          val newthing = for {
            fieldValues <- OpenCtiUtil.getOptionsForField(Some(selOpt.value))
          } yield {
            (selOpt, fieldValues)
          }
          newthing.value
        })
      val futureOfListEither: Future[
        List[Either[List[ErrorMessage], (Models.SelectOption, List[Models.SelectOption])]]
      ] = Future.sequence(getOptions)
      val futureOfListOption = futureOfListEither.map(fut =>
        fut.map(e =>
          e match {
            case Right(v) => Some(v)
            case Left(b) => None
          }
        )
      )

      val futureOfListTuple: scala.concurrent.Future[List[
        (SelectOption, List[com.sludg.model.Models.SelectOption])
      ]] = futureOfListOption.map(_.flatten)

      futureOfListTuple.map(a => {
        PhonePage.logger.info("Fetching Call Log Options")
        val newMap: Map[SelectOption, List[com.sludg.model.Models.SelectOption]] =
          a.map(a => (a._1, a._2)).toMap
        newMap
      })
    } else {
      Future.successful(Map[SelectOption, List[com.sludg.model.Models.SelectOption]]())
      // Do nothing if no Options are defined
    }
  }

  def getClickToCallDevice(api: PhoneApi)(implicit token: SludgToken) = {

    api
      .getUserPhones(token.tenants(0), token.subscribers(0).ext.toInt)
      .map({
        case Right(value) => value.filter(u => u.defaultC2CPhone).headOption
        case Left(value) => None
      })
  }

  def mapLeadToContact(sfLead: SFLead): SFContact = {
    new SFContact(
      id = sfLead.id,
      Name = sfLead.Name,
      Phone = sfLead.Phone,
      RelatedAccountName = sfLead.Company
    )
  }

  /** Searches salesforce based on the call.from `SFContact` related to the phone number and its related Properties from Salesforce and updates Call Data`
    * Returns and updated Call Data with salesforce data.
    * @param call // CallData
    */
  def searchContact(call: CallData): EitherT[Future, List[ErrorMessage], CallData] = {
    PhonePage.logger.debug("Searching Contacts")
    PhonePage.logger.debug(s"With search query ${call.from} and call type ${call.callType}")

    val component = this.asInstanceOf[PhonePageComponent]
    val result = EitherT[Promise, List[ErrorMessage], CallData](Promise())

    OpenCtiUtil.searchAndScreenPop(call.from, call.callType)(callBack = e => {
      if (e.success) {
        PhonePage.logger.info("Search contact success")
        PhonePage.logger.info(JSON.stringify(e.returnValue))

        val SFDynamic = e.returnValue

        val objectArray: js.Array[js.Dynamic] =
          OpenCtiUtil.convertJsDyanmicObjectToArray(e.returnValue)

        val splitSFObjects: Map[SFObjectType, Seq[SFObject]] =
          OpenCtiUtil.convertAndSeparateSFObjects(objectArray)

        val relatedContactsObjects = splitSFObjects.getOrElse(SFContactType, Nil)

        val relatedContacts: List[SFContact] =
          if (relatedContactsObjects.nonEmpty)
            relatedContactsObjects.map(_.asInstanceOf[SFContact]).toList
          else Nil

        val relatedCasesObjects = splitSFObjects.getOrElse(SFCaseType, Nil)

        val relatedCases: List[SFCase] =
          if (relatedCasesObjects.nonEmpty)
            relatedCasesObjects.map(_.asInstanceOf[SFCase]).toList
          else Nil

        val relatedCasesNotClosed = relatedCases.filterNot(c => c.Status.contains("Closed"))

        val supportCases = relatedCasesNotClosed.filterNot(c => c.Type.contains("Support"))

        val provisioningCases =
          relatedCasesNotClosed.filterNot(c => c.Type.contains("Provisioning"))

        val relatedOpportunityObjects =
          splitSFObjects.getOrElse(SFOpportunityType, Nil)

        val relatedLeadsObjects = splitSFObjects.getOrElse(SFLeadType, Nil)

        val relatedLeads: List[SFLead] =
          if (relatedLeadsObjects.nonEmpty)
            relatedLeadsObjects.map(_.asInstanceOf[SFLead]).toList
          else Nil

        val relatedOpportunities: List[SFOpportunity] =
          if (relatedOpportunityObjects.nonEmpty)
            relatedOpportunityObjects.map(_.asInstanceOf[SFOpportunity]).toList
          else Nil

        val relatedSFObjects: List[SFObject] =
          supportCases ::: relatedOpportunities ::: relatedLeads

        val sfContactFound: Option[SFContact] =
          if (relatedContacts.isEmpty && relatedLeads.nonEmpty) {
            Some(mapLeadToContact(relatedLeads.head))
          } else if (relatedContacts.size == 1) { relatedContacts.headOption }
          else None

        val selectableObjects = getSelectableSFObjects(splitSFObjects)

        val updatedCall: CallData = call.copy(
          sfContact = sfContactFound,
          relatedContacts = relatedContacts,
          relatedSfObjects = relatedSFObjects,
          searchedSalesforce = true,
          selectableSFObjects = selectableObjects
        )

        result.value.success(Right(updatedCall))

      } else {
        PhonePage.logger.debug("Failed to search")
        PhonePage.logger.debug(JSON.stringify(e.errors))
        // Error
        component.$root.$emit(
          "display-message",
          SnackBarMessage(
            "Server Error: Failed to search contacts",
            DisplayMessageType.Error
          )
        )
        result.value.success(Left(e.errors.toList))
      }
    })

    for (value <- EitherT(result.value.future)) yield (value)
  }

  /** Takes Map of SFObjects and converts it into list of VComboBoxProp
    *
    * @param sfObjectMap
    * @return
    */
  def getSelectableSFObjects(
      sfObjectMap: Map[SFObjectType, Seq[SFObject]]
  ): List[Either[VComboboxProps.StaticItem, SFObject]] = {

    val sfCase: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFCaseType, Nil).nonEmpty) {
        val s: List[Right[Nothing, SFObject]] =
          sfObjectMap.getOrElse(SFCaseType, Nil).map(c => Right(c)).toList
        Left(new VComboboxProps.StaticItem("Cases")) :: s
      } else Nil

    val sfLead: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFLeadType, Nil).nonEmpty) {
        val s: List[Right[Nothing, SFObject]] =
          sfObjectMap.getOrElse(SFLeadType, Nil).map(c => Right(c)).toList
        Left(new VComboboxProps.StaticItem("Leads")) :: s
      } else Nil

    val sFOpportunity: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFOpportunityType, Nil).nonEmpty) {
        Left(new VComboboxProps.StaticItem("Opportunities")) :: sfObjectMap
          .getOrElse(SFOpportunityType, Nil)
          .map(c => Right(c))
          .toList
      } else Nil

    val accounts: Seq[SFAccount] = sfObjectMap
      .getOrElse(SFAccount.SFAccountType, Nil)
      .map(_.asInstanceOf[SFAccount])

    val sFOther: List[Either[VComboboxProps.StaticItem, SFObject]] =
      if (sfObjectMap.getOrElse(SFOtherObjectType, Nil).nonEmpty) {
        Left(new VComboboxProps.StaticItem("MISC (not considered)")) :: sfObjectMap
          .getOrElse(SFOtherObjectType, Nil)
          .map(c => Right(c))
          .toList
      } else Nil

    sfCase ::: sFOpportunity ::: sfLead ::: sFOther
  }

  def connectToSilhouetteEventBus(
      websockets: Websockets,
      api: PhoneApi,
      component: PhonePageComponent,
      sludgToken: SludgToken
  )(implicit ec: ExecutionContext) = {

    implicit val cs = IO.contextShift(ec)
    implicit val sleep = Sleep.sleepUsingTimer(IO.timer(ec))

    val clickToDialEnabler = PhonePageUtil.retryReportingToSnackbarOnError(
      component,
      "An error occurred trying to enable click to dial",
      PhonePageUtil.enableClickToDial
    ) >> PhonePageUtil.initialiseClickToCallCallback(component, api, sludgToken)

    def eventListener(component: PhonePageComponent) =
      PhonePageUtil.retryReportingToSnackbarOnError(
        component,
        "An error occurred trying to connect",
        for {
          token <- IO {
            println("Inside IO")
            val tokenboy = component.token
            println("After val")
            tokenboy
          }.flatMap(
            _.fold(
              IO.raiseError[SludgToken](
                PhonePageUtil.ConnectionError("Cannot connect due to a lack of valid token")
              )
            )(IO.pure(_))
          )
          socket <- websockets.tows(implicitly, token) match {
            case Left(value) =>
              IO.raiseError(
                PhonePageUtil.ConnectionError("User not linked - please link via User Portal")
              )
            case Right(value) =>
              value
                .evalMap(PhonePageUtil.websocketProcessor(component))
                .compile
                .drain
                .onError({
                  case a @ ConnectionError(e) => {
                    a.printStackTrace()
                    println(s"Message was : ${a.message}")
                    println(a)
                    println(e.toString)
                    IO(())
                  }
                })
          }
        } yield ()
      )

    component.connectionCanceller = Some(
      (eventListener(component), clickToDialEnabler).parTupled.unsafeRunCancelable {
        case Left(value) =>
          PhonePage.logger.error(value)("The connection terminated unexpectedly.")
          displayMessage(
            component,
            SnackBarMessage(
              "Connection to the server has been lost. Please refresh the page",
              DisplayMessageType.Error
            )
          )
          PhonePage.logger.info("The connection terminated.")
        case Right(_) =>
          displayMessage(
            component,
            SnackBarMessage(
              "Connection to the server has been lost. Please refresh the page",
              DisplayMessageType.Error
            )
          )
      }
    )

  }

  /** Updates queue on each Call Event recieved from Silhouette.
    *
    * @param silhouetteId // CallId for each Call Event
    * @param event // Individual Call Event
    * @param isOutgoingCall // If the Call direction is an outgoing
    * @param transferred // if the Call is transferred
    */
  def updateQueue(
      component: PhonePageComponent,
      silhouetteId: String,
      event: CallEvent,
      isOutgoingCall: Boolean = false,
      transferred: Boolean = false
  ): Unit = {
    PhonePage.logger.info("Updating Queue - Event Recieved")
    PhonePage.logger.info(s"component defined: ${js.isUndefined(component)}")

    def getExternalNumber(event: CallEvent, outgoing: Boolean): Option[String] =
      event match {
        case r: CallReceived => Some(r.from)
        case d: CallDialed => Some(d.dialedDigits)
        case t: CallTransfer => Some(t.transferee)
        case _ => None
      }

    def updateCurrentCallData(
        silhouetteId: String,
        callFrom: String,
        event: CallEvent,
        lastEvent: CallEvent,
        callActive: Boolean,
        outgoing: Boolean,
        transferred: Boolean,
        callType: CallType
    ) = {
      PhonePage.logger.info("Updating current Call Data")
      if (component.currentCallData.exists(a => a.Id == silhouetteId)) {
        PhonePage.logger.info("Found existing callId - Updating Current Call")
        // currentQueue event exists and has a matching id
        // If it rings
        event match {
          // Missed Call
          case e: CallEnd if lastEvent.isInstanceOf[CallReceived] => {
            PhonePage.logger.info("Call Ended before being picked up")
            component.currentCallData = None
          }
          case e: CallEnd if lastEvent.isInstanceOf[CallRinging] => {
            PhonePage.logger.info("Call Ended before being picked up")
            component.currentCallData = None
          }
          case _ => {
            PhonePage.logger.info("Call was picked up - Updating call event")
            component.currentCallData = Some(
              component.currentCallData.get.copy(
                lastEvent = event,
                transferred = transferred,
                callType = callType,
                outgoing = outgoing,
                callActive = callActive
              )
            )
          }
        }
      } else {
        PhonePage.logger.info("Found no existing callId - Initialising Current Call")
        if (component.currentCallData.isDefined) {
          // A call is currently active so do nothing
        } else {
          // Auto-selection
          component.currentCallData = Some(
            CallData(
              silhouetteId,
              callFrom,
              event,
              callActive,
              transferred = transferred,
              callType = callType
            )
          )
        }
      }
    }

    PhonePage.logger.info(s"call queue defined: ${js.isUndefined(component.callQueue)}")

    val foundEventMaybe: Option[CallData] =
      component.callQueue.find{event => 
        PhonePage.logger.info(s"Queue event defined: ${js.isUndefined(component.callQueue)}")
        event.Id == silhouetteId
      }

    PhonePage.logger.info(s"Found an event: ${foundEventMaybe.isDefined}")

    foundEventMaybe match {
      case Some(c) =>
        // Found Data
        val externalNumber = getExternalNumber(event, isOutgoingCall) match {
          case Some(newExternalNumber) => newExternalNumber
          case None => c.from
        }
        
        PhonePage.logger.info(s"External number: ${externalNumber}")

        // Updating current Call Data with latest call event
        updateCurrentCallData(
          silhouetteId,
          externalNumber,
          event,
          c.lastEvent,
          c.callActive,
          c.outgoing,
          transferred,
          callType = c.callType
        )

        PhonePage.logger.info(s"Phone data updated")

        // Index of current
        val eventIndex = component.callQueue.indexOf(c)

        PhonePage.logger.info(s"event process CQ Pre: ${js.isUndefined(component.callQueue)}")
        component.callQueue = event match {
          case e: CallEnd
              if c.lastEvent.isInstanceOf[CallRinging] || c.lastEvent
                .isInstanceOf[CallReceived] || e.endReason == "transferred" || c.lastEvent
                .isInstanceOf[CallError] => {
            // Calls that end without being answered are filtered
            component.callQueue.patch(eventIndex, Nil, 1)
          }
          case r: CallTransfer if c.lastEvent.isInstanceOf[CallReceived] => {
            OpenCtiUtil.makeSoftPhoneVisibleIfClosed()
            // Incoming transfer appears as incoming call
            val updatedCallData = CallData(
              c.Id,
              externalNumber,
              c.lastEvent,
              c.callActive,
              transferred = transferred,
              callType = Sforce.opencti.CALL_TYPE.INBOUND
            )
            component.callQueue.patch(eventIndex, List(updatedCallData), 1)
          }
          case _ => {
            // CallForward(_, _, _, _, _, _), CallTransfer(_, _, _, _, _, _, _), PresenceEvent(_, _, _, _)
            // Normal Call
            OpenCtiUtil.makeSoftPhoneVisibleIfClosed()
            val updatedEvent = CallData(
              c.Id,
              externalNumber,
              event,
              c.callActive,
              transferred = transferred,
              callType = c.callType
            )
            component.callQueue.patch(eventIndex, List(updatedEvent), 1)
          }
        }
        PhonePage.logger.info(s"event process CQ Post: ${js.isUndefined(component.callQueue)}")
      case None =>

        PhonePage.logger.info(s"New call")
        // New Call
        // Transfer/Offer
        OpenCtiUtil.makeSoftPhoneVisibleIfClosed()
        val externalNumberOpt = getExternalNumber(event, isOutgoingCall)

        if (externalNumberOpt.isDefined) {
          val externalNumber = externalNumberOpt.get
          PhonePage.logger.info(s"External number: $externalNumber")
          if (externalNumber.length < 5) {
            PhonePage.logger.info(s"event process 2 CQ Pre: ${js.isUndefined(component.callQueue)}")
            component.callQueue = component.callQueue.++(
              List(
                CallData(
                  event.Id,
                  externalNumber,
                  event,
                  transferred = transferred,
                  callType = Sforce.opencti.CALL_TYPE.INTERNAL
                )
              )
            )
            PhonePage.logger.info(s"event process 2 CQ Post: ${js.isUndefined(component.callQueue)}")
          } else {
            PhonePage.logger.info(s"event process 3 CQ Pre: ${js.isUndefined(component.callQueue)}")
            component.callQueue = component.callQueue.++(
              List(
                CallData(
                  event.Id,
                  externalNumber,
                  event,
                  transferred = transferred,
                  callType =
                    if (isOutgoingCall) Sforce.opencti.CALL_TYPE.OUTBOUND
                    else Sforce.opencti.CALL_TYPE.INBOUND
                )
              )
            )
            PhonePage.logger.info(s"event process 3 CQ Post: ${js.isUndefined(component.callQueue)}")
          }
        } else {
          PhonePage.logger.info(s"No external number")
        }
    }
  }
}
