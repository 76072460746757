package com.sludg.client

import com.sludg.salesforce.JsonDeserializers._
import play.api.libs.json.Json
import play.api.libs.json.JsSuccess
import com.sludg.salesforce._
import scala.scalajs.js
import scala.scalajs.js.JSON
import cats.data.EitherT
import scala.concurrent.Promise
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import cats.implicits._
import cats.data._
import cats.instances.future.catsStdInstancesForFuture
import scala.concurrent.Promise
import cats.data.EitherT
import cats.implicits._
import com.sludg.model.Models.SelectOption
import play.api.libs.json.JsSuccess
import org.log4s.getLogger
import com.sludg.services.EventBus
import com.sludg.services.EventBusEvent
import com.sludg.models.Status.PhoneStatus

/**
  * Utility Class for OpenCti methods pure from all reactive data sins!
  */
object OpenCtiUtil {

  private[this] val logger = getLogger

  /** Converts a a js dynamic object to its respective supported salesforce type */
  def convertDynamicToSFObject(
      dynamic: js.Dynamic
  ): Option[SFObject] = {
    if (js.isUndefined(JSON.stringify(dynamic))) {
      None
    } else {
      if (JSON.stringify(dynamic) == "null") {
        None
      } else {
        dynamic.selectDynamic("RecordType").toString match {
          case "Case" =>
            Json.parse(JSON.stringify(dynamic)).asOpt[SFCase]
          case "Contact" =>
            Json.parse(JSON.stringify(dynamic)).asOpt[SFContact]
          case "Opportunity" =>
            Json.parse(JSON.stringify(dynamic)).asOpt[SFOpportunity]
          case "Account" =>
            Json.parse(JSON.stringify(dynamic)).asOpt[SFAccount]
          case "Lead" =>
            Json.parse(JSON.stringify(dynamic)).asOpt[SFLead]
          case a => {
            // Currently removing all SForceUtil objects
            None
          }
        }
      }
    }
  }

  def enableClickToDialOnSalesforce(eventBus: EventBus) = Sforce.opencti.enableClickToDial(EnableClickToDialArguments(
    CallBackArgument(e => {
      if (e.success) {
        logger.debug("Click to dial is enabled")
        // Available
        eventBus.emit(EventBusEvent.Notification(PhoneStatus.Connected))
      } else {
        // SalesforceError
        eventBus.emit(EventBusEvent.Notification(PhoneStatus.Error("Salesforce could not enable Click To Dial")))
        logger.error("Click To Dial couldn't be enabled")
      }
    })
  ))

  def convertJsDyanmicObjectToArray(dynamic: js.Object): js.Array[js.Dynamic] = 
    if (js.isUndefined(dynamic)) {
        js.Array()
    } else {
        js.Object
          .keys(dynamic)
          .map(key => {
            dynamic.asInstanceOf[js.Dynamic].selectDynamic(key)
        })
    }

  // TODO move to spa
  trait SFLayoutObjects {
    val displayName: String
    val apiName: String
  }

  trait CallTypeObject {
    val callRelatedFields: List[String]
    val objects: List[SFLayoutObjects]
    // move Values available
  }
  trait Internal extends CallTypeObject
  trait Outbound extends CallTypeObject
  trait Inbound extends CallTypeObject

  trait SoftPhoneLayout {
    val Internal: Internal
    val Outbound: Outbound
    val Inbound: Inbound
  }

  // Gets the Softphone layout object
  def getSoftphoneLayout: EitherT[Future, List[ErrorMessage], SoftPhoneLayout] = {
    var result = EitherT[Promise, List[ErrorMessage], SoftPhoneLayout](Promise())
    Sforce.opencti.getSoftphoneLayout(SalesforceCallback[SalesForceResponse[js.Dynamic]](e => {
      if (e.success) {
        val softphoneLayout = e.returnValue.asInstanceOf[SoftPhoneLayout]
    
        result.value.success(Right(softphoneLayout ))
      } else {
        result.value.success(Left(e.errors.toList))
      }
    }))

    for (
      options <- EitherT(result.value.future)
    ) yield(options)
  }

  // Goes through the salesforce object and returns a mapping with types (types are used for splitting)
  def convertAndSeparateSFObjects(
      objectArray: js.Array[js.Dynamic]
  ): Map[SFObjectType, Seq[SFObject]] = {

    val convertedSFObjects: Seq[SFObject] =
      objectArray.map(a => convertDynamicToSFObject(a)).toList.flatten

    val splitObjects: Map[SFObjectType, Seq[SFObject]] =
      convertedSFObjects.groupBy[SFObjectType](
        a =>
          a match {
            case a: SFContact     => SFContact.SFContactType
            case b: SFCase        => SFCase.SFCaseType
            case c: SFOpportunity => SFOpportunity.SFOpportunityType
            case a: SFAccount     => SFAccount.SFAccountType
            case l: SFLead        => SFLead.SFLeadType
            case _                => SFObject.SFOtherObjectType
          }
      )

    splitObjects
  }

  def makeSoftPhoneVisibleIfClosed(): Future[List[ErrorMessage]] = {
    isSoftphoneVisible().value.flatMap(e => e.fold(e => Future.successful(e), v => if (v) Future.successful(Nil) else {
      setSoftPhoneVisiblity(true).value.flatMap(e => e.fold(err => Future.successful(err), f => Future.successful(Nil)))
    }))
  }

  def convertToSFObjectsArray(objectArray: js.Array[js.Dynamic]): List[SFObject] =
    objectArray.map(a => convertDynamicToSFObject(a)).toList.flatten

  def screenPop(paramObject: ScreenPopParam) = {
    Sforce.opencti.screenPop(ScreenPopArguments(
      paramObject
    ))
  }

   def searchAndScreenPop(
      searchParam: String,
      callType: CallType,
      deferred: Boolean = true,
      defaultFieldsValue: Option[DefaultFieldsValue] = None
  )(callBack: SearchAndScreenPopPayLoad => Unit) = {
    Sforce.opencti.searchAndScreenPop(
      SearchAndScreenPopArguments(
        Some(searchParam),
        None,
        defaultFieldsValue,
        Some(callType),
        Some(deferred),
        Some(CallBackArgument(callBack))
      )
    )
  }

  def setSoftPhoneVisiblity(visiblity: Boolean): EitherT[Future, List[ErrorMessage], Unit] = {
    var result = EitherT[Promise, List[ErrorMessage], Unit](Promise())
    Sforce.opencti.setSoftphonePanelVisibility(SetSoftPhoneVisbilityArguments[SetSoftphonePanelVisibilityPayload](
      visiblity,
      Some(CallBackArgument(r => {
        if (r.success) {
          result.value.success(Right(()))
        } else {
          result.value.success(Left(r.errors.toList))
        }
      }))
    ))
    for {
      u <- EitherT(result.value.future)
    } yield u
  }


  def isSoftphoneVisible(): EitherT[Future, List[ErrorMessage], Boolean] = {
    var result = EitherT[Promise, List[ErrorMessage], Boolean](Promise())

    Sforce.opencti.isSoftphonePanelVisible(IsSoftPhoneVisibleArguments[IsSoftPhoneVisiblePayload](
      Some(CallBackArgument(r => {
        if (r.success) {
          result.value.success(Right(r.returnValue.visible))
        } else {
          result.value.success(Left(r.errors.toList))
        }
      }))
    ))
    for {
      visibility <- EitherT(result.value.future)
    } yield visibility
  }


  def getOptionsForField(fieldNameOpt: Option[String]): EitherT[Future, List[ErrorMessage], List[SelectOption]] = {
    logger.debug("GETTING OPTIONS FOR FIELD")
    logger.debug(fieldNameOpt.toString)
    var result = EitherT[Promise, List[ErrorMessage], List[SelectOption]](Promise())
    fieldNameOpt match {
      case Some(fieldName) => {

    Sforce.opencti.runApex(
      RunApexArguments(
        "OpenCtiUtil",
        "getPickValues",
        s"fieldName=${fieldName}&sObjectName=Task",
        Some(CallBackArgument[RunApexCallbackPayload](e => {
          if (e.success) {
            logger.debug("GETTING PICKLIST")
            val listOfFields = e.returnValue.runApex
            import com.sludg.model.SettingsModelsSerializers._
            
            val listOfOptions: List[SelectOption] = Json
            .parse(listOfFields.toString)
            .validate[List[SelectOption]] match {
              case JsSuccess(a: List[SelectOption], _) => a
              case _ => Nil
            }

            val listOfFieldsConvertedL = listOfOptions

            result.value.success(Right(listOfFieldsConvertedL))
          } else {
            logger.debug("FAILURE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
            JSON.stringify(e.errors)
            e.errors.foreach(a => JSON.stringify(a))
            // Left(e.errors.map(_.toString))
            result.value.success(Left(e.errors.toList))
          }
        }))
      )
    )

    }
      case None => result.value.success(Left(List(ErrorMessage("Log Field Error", "Field selected for logging isn't supported"))))
    }
    for {
      options <- EitherT(result.value.future)
    } yield options
  }

  // TODO should handle error from salesforce
   def searchAndPopAccount(accountName: String, user: SFContact) = {
      searchAndScreenPop(
        accountName,
        Sforce.opencti.CALL_TYPE.INBOUND,
        true,
        None
      )(e => {
        val sFDynamic = e.returnValue
        val sFDynamicArray: js.Array[js.Dynamic] = OpenCtiUtil.convertJsDyanmicObjectToArray(sFDynamic)
        val sFAccounts: Seq[SFAccount] = OpenCtiUtil.convertAndSeparateSFObjects(sFDynamicArray).getOrElse(SFAccount.SFAccountType, Nil).map(_.asInstanceOf[SFAccount])
        val relatedAccount: Option[SFAccount] = sFAccounts.filter(acc => acc.Name == user.RelatedAccountName).headOption      
        relatedAccount match {
        case Some(acc) => OpenCtiUtil.screenPop(ScreenPopParam.SObjectParam(acc.id, None))
        case None => OpenCtiUtil.searchAndScreenPop(
        user.RelatedAccountName.get,
        Sforce.opencti.CALL_TYPE.INBOUND,
        false,
        None
        )(e => ())
      }})
    }
}
