package com.sludg

import java.util.Base64

import com.sludg.auth0.SludgToken
import com.sludg.client.Client
import com.sludg.model.Models.SalesforcePhoneConfig
import com.sludg.services.{ApiCalls, PhoneApi}
import org.log4s._
import org.scalajs.dom.ext.LocalStorage
import play.api.libs.json.Json

import scala.concurrent.Future
import scala.scalajs.js.{Dynamic, JSON}
import scala.util.{Failure, Success}

/**
  * @author dpoliakas
  *         Date: 13/11/2018
  *         Time: 14:54
  *
  *
  */

object Entry extends App {

  private[this] val logger = getLogger

  import monix.execution.Scheduler.Implicits.global

  val originUrl = org.scalajs.dom.window.location.href

  def getConfig: Option[SalesforcePhoneConfig] = {
    import com.sludg.model.Models.salesforcePhoneConfigConfigFormat
    val configObject: String = Dynamic.global.vtslAppConfig.asInstanceOf[String]
    val decodedConfigObject = Base64.getDecoder().decode(configObject).map(_.toChar).mkString
    val salesforceConfigOpt: Option[SalesforcePhoneConfig] = Json.parse(decodedConfigObject).asOpt[SalesforcePhoneConfig]
    salesforceConfigOpt
  }

  def initLogging(level: Option[LogLevel]): Unit = {
    import org.log4s.log4sjs.Log4sConfig._
    level match {
      case Some(logLevel) => setLoggerThreshold("", logLevel)
      case None => setLoggerThreshold("", OffThreshold)
    }
  }

  getConfig match {
    case Some(config) =>
      // Setup log level
      initLogging(config.logLevel)
      (for {
        maybeSecurity <- Security.initialise(Auth0Config(config.auth0.domain, config.auth0.clientID, config.auth0.audience, originUrl), sessionStorage = LocalStorage)
      } yield {
        maybeSecurity match {
          case Left(Security.NotLoggedIn(redirectToLogin)) =>
            logger.info("User not logged in. Redirecting.")
            redirectToLogin.run()
          case Right(sec) =>
            logger.info("Security session initialised. Bootings...")
            sec.checkSession().onComplete {
              case Success(Some(tokenJs)) => Client(sec, config, config.otherApps, new PhoneApi(config.apiConfig), "main", new ApiCalls(config.apiConfig), new SludgToken(tokenJs.accessToken))
              case Success(None) => logger.debug("Could not find token"); sec.logout()
              case Failure(error) => logger.debug("Could not refresh the token")
                error.printStackTrace()
                logger.debug("Logging out.")
                // sec.logout()
            }
        }
      }).onComplete {
        case Success(_) => logger.debug("Running application")
        case Failure(e) =>
          logger.error("failed to start the application")
          e.printStackTrace()
          logger.debug(e.getMessage)
      }

    case None => logger.error("Config not supplied or could not be parsed")

  }

}

