package com.sludg.client.pages.settings

/** @author Ali S. (CM9000)
  */
import java.time.ZoneId
import java.time.format.DateTimeFormatter
import cats.data.EitherT
import cats.instances.future.catsStdInstancesForFuture
import com.sludg.client.VtslPhone
import com.sludg.client.components._
import com.sludg.client.components.callpanel.{CallPanel, CallPanelProps}
import com.sludg.client.pages.settings.SettingsMenuPage.SettingsMenuPageComponent
import com.sludg.client.pages.settings.SettingsPageRenderFunctions._
import com.sludg.salesforce.JsonDeserializers._
import com.sludg.helpers.AppSetup._
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.services.{ApiCalls, EventBus, EventBusEvent, PhoneApi}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{
  EventBindings,
  RenderHelpers,
  RenderOptions,
  ScopedSlots,
  Vue,
  VueProps,
  WithRefs,
  _
}
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.Security
import com.sludg.client.pages.main.PhonePage.PhonePageComponent
import com.sludg.models.CallControlModels._
import com.sludg.models.Status.PhoneStatus.Connected
import com.sludg.models.Status.{PhoneStatus, UserStatus}
import com.sludg.salesforce._
import com.sludg.util.models.SilhouetteModels.{UserPhone, Tenant}
import org.log4s.getLogger
import play.api.libs.json.Json
import play.api.libs.json.JsSuccess
import monix.execution.Scheduler.Implicits.global
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.JSON
import org.scalajs.dom.ext.LocalStorage
import org.scalajs.dom.window
import js.JSConverters._
import com.sludg.util.models.Events.CallOriginatedEvent
import com.sludg.client.pages.settings.SettingsUtil._
import com.sludg.model.Models._
import com.sludg.model.SettingsModels._
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.models.Models.AccessForbidden
import java.nio.channels.SelectionKey
import com.sludg.vue.rendering.RouterView
import scala.scalajs.js.UndefOr
import com.sludg.model.SettingsModels.ConfigurationType.LogCaseComment
import play.api.libs.json._
import com.sludg.model.SettingsModelsSerializers._
import java.util.Timer
import com.sludg.util.TokenUtil
import com.sludg.client.pages.helper.Helper
import com.sludg.helpers.LoadingFuture

/** SettingsMenuPage is used to displays `List[Setting]` and its `List[Configuration]`
  * Each configuration when clicked on takes you to its individual page `/settings/{configurationType}`
  * This is displayed via the SettingsDisplayer
  */
object SettingsMenuPage {

  import com.sludg.vue.RenderHelpers._
  import com.sludg.vuetify.components.VButtonProps

  private[pages] val logger = getLogger

  type SettingsMenuPageComponent =
    VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
      with SettingsMenuPageData
      with VueProps
      with WithRefs[_ <: Refs]

  val vtslPhone = VtslPhone.VtslPhoneRenderer("VtslPhone")
  val statusBar = StatusBar.StatusBarRenderer("StatusBar")
  val callPanel = namedTag[CallPanelProps, EventBindings, ScopedSlots]("CallPanel")
  val settingDisplayer = SettingDisplayer.SettingDisplayerRenderer("SettingDisplayer")

  def SettingsMenuPageRenderer(registrationName: String) =
    namedTag[VueProps, EventBindings, ScopedSlots]("SettingsMenuPage")

  def SettingsMenuPageComponent(
      apiCalls: ApiCalls,
      security: Security,
      loaderEventBus: Vue,
      otherApps: List[AppLink],
      token: SludgToken
  ) = {

    val data = new SettingsMenuPageData()

    VueComponent.builder
      .withData(data)
      .build(
        destroyed = js.defined(c => {
          c.refreshTimer match {
            case Some(timer) =>
              logger.debug("component destroyed and token refresh timer cancelled"); timer.cancel()
            case None => logger.debug("component destroyed but not timer was found")
          }
        }),
        created = js.defined(c => {
          import org.scalajs.dom.ext.LocalStorage
          c.token = Some(token)

          LoadingFuture.withLoading(
            loaderEventBus,
            Helper
              .tokenValidator(security, apiCalls, token)
              .map({
                case t @ Some(validToken) => {
                  c.token = t
                  val caseCommentSelectionOpt =
                    SettingsUtil.getSelection[Single[Boolean]](LogCaseComment, LocalStorage)(
                      singleSelectionBooleanFormat
                    )

                  c.settings = SettingsUtil.updateCaseCommentSelection(
                    SettingsUtil.getDefaultSettings(),
                    caseCommentSelectionOpt,
                    Api(apiCalls, validToken)
                  )

                  c.refreshTimer = Some(
                    TokenUtil.tokenRefresher(
                      validToken.tokenString,
                      (newToken: Option[SludgToken]) => {
                        c.token = newToken
                      },
                      security
                    )
                  )
                }
                case None => security.logout()
              })
          )

        }),
        components = js.Dynamic.literal(
          "VtslPhone" -> VtslPhone
            .VtslPhoneComponent(apiCalls, security, loaderEventBus, otherApps),
          "SettingDisplayer" -> SettingDisplayer
            .SettingDisplayerComponent(apiCalls, security, loaderEventBus, otherApps, token)
        ),
        templateOrRender = Right((component, renderer) => {
          vtslPhone(
            RenderOptions(
              props = Some(
                new VtslAppProps(
                  title = js.defined("Settings Page"),
                  spacedToolbar = true,
                  temporaryDrawer = true
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(e => {
                      vLayout(
                        vFlex(),
                        vFlex(
                          "Settings"
                        ),
                        vFlex()
                      ).render(renderer)
                    })
                  override val default: js.UndefOr[js.Function1[Option[Any], VNode]] =
                    js.defined(tenantOpt => {
                      div(
                        component.token match {
                          case Some(token) => renderSettingsMenu(component, Api(apiCalls, token))
                          case None => nothing
                        }
                      ).render(renderer)
                    })
                  override val toolbarLeft: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(t => {
                      vToolbarSideIcon(
                        vIcon("arrow_back"),
                        click(_ => component.$router.push("/"))
                      ).render(renderer)
                    })
                }
              )
            )
          ).render(renderer)
        })
      )
  }
}
class SettingsMenuPageData extends js.Object {
  var settings: List[Setting] = List()
  var token: Option[SludgToken] = None
  var refreshTimer: Option[Timer] = None
}
