package com.sludg.client.components.callpanel

import com.sludg.auth0.SludgToken
import java.time.ZoneId
import java.time.format.DateTimeFormatter
import cats.data.EitherT
import cats.data._
import cats.implicits._
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import com.sludg.Security
import com.sludg.client.components._
import com.sludg.client.components.callpanel.CallPanel.CallPanelComponent
import com.sludg.client.components.callpanel.CallPanelRenderFunctions._
import com.sludg.client.pages.main._
import com.sludg.salesforce.JsonDeserializers._
import com.sludg.models.CallControlModels._
import com.sludg.models.Config.AppLink
import com.sludg.salesforce._
import com.sludg.services.PhoneApi
import com.sludg.vue.{EventBindings, Vue, _}
import com.sludg.vuetify.components.VComboboxProps
import org.log4s.getLogger
import play.api.libs.json.Json
import com.sludg.salesforce._
import cats.data.EitherT
import cats.implicits._
import cats.instances.future.catsStdInstancesForFuture
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.JSON
import com.sludg.model.Models.CallData
import com.sludg.client.OpenCtiUtil
import com.sludg.client.pages.settings.SettingsUtil
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import com.sludg.model.SettingsModels._
import com.sludg.model.Models.SelectOption
import play.api.libs.json.JsSuccess
import play.api.libs.json.Reads
import play.api.libs.json.JsResult
import play.api.libs.json.OFormat
import org.scalajs.dom.ext.LocalStorage
import play.api.libs.json._
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.model.SettingsModels.ConfigurationType.LogCaseComment

/**
  * CallPanelComponent has 4 main responsibilities.
  * 1. Display related Salesforce Objects on the currentCall or display a button to Screenpop a "Create new Contact" modal in Salesforce. 
  * 2. Allow selection of Call Log options dynamically. (Currently Picklist types are supported)
  * 3. Relay (Emit) The data back to `PhonePageComponent`
  * 4. Log Calls to salesforce with the selected fields.
  *
  * @author Ali S. (CM9000)
  */
object CallPanel {

  import com.sludg.vue.RenderHelpers._

  private[callpanel] val logger = getLogger

  type CallPanelComponent =
    VueComponent[_ <: VueProps, _ <: Slots, _ <: ScopedSlots]
      with js.Object
      with CallPanelMethods
      with js.Object
      with CallPanelProps
      with WithRefs[_ <: Refs]

  val dialPad = DialPad.DialPadRenderer("DialPad")
  val ticker = Ticker.TickerRenderer("Ticker")

  def CallPanelRenderer(registrationName: String) =
    namedTag[CallPanelProps, EventBindings, ScopedSlots]("CallPanel")
  
  def CallPanelComponent(
      apiCalls: PhoneApi,
      security: Security,
      loaderEventBus: Vue,
      otherApps: List[AppLink]
  ) = {

    val methods = new CallPanelMethods()
    
    import com.sludg.FieldExtractor

    VueComponent.builder
      .withMethods(methods)
      .withPropsAs[CallPanelProps]
      .build(
        created = js.defined(c => {
          // Logs
          logger.debug("Call Panel created")
          logger.debug("The logOptions Map")
          logger.debug(c.logOptions.map(_.toMap).toString)
          logger.debug(c.logOptions.toString)
          if (js.isUndefined(c.currentCall)) {
            logger.debug(s"Current Call is undefined")
          } else {
            logger.debug(s"Current Call is defined")
          }
        }),
        updated = js.defined(c => {
          logger.info("Call Panel Updated")
          logger.debug(s"Current Call: ${c.currentCall}")
        }),
        components = js.Dynamic.literal(
          "Ticker" -> Ticker
            .TickerComponent(apiCalls, security, loaderEventBus, otherApps)
        ),
        templateOrRender = Right((component, renderer) => {
          val currentCall: CallData = component.currentCall
          val currentSFContact = component.currentCall.sfContact

          logger.debug(s"${currentCall.relatedContacts.size} relatedContacts, rendering contact select")
          logger.debug(s"${currentCall.relatedContacts}")
          div(
              currentCall.lastEvent match {
                  case r: CallReceived => receivedCard(r, component, renderer, apiCalls, currentSFContact)
                  case d: CallDialed => callDialled(d, component, renderer, apiCalls, currentSFContact)
                  case a: CallAnswered => answeredCard(a, component, renderer, apiCalls, currentSFContact)
                  case e: CallEnd       => endedCard(e, component, renderer, currentSFContact)
                  case h: CallHold      => holdCard(h, component, renderer, currentSFContact)
                  case re: CallResume   => resumeCard(re, component, renderer, currentSFContact)
                  case ri: CallRinging  => ringingCard(ri, component, renderer, currentSFContact)
                  case rb: CallRingBack => ringBackCard(rb, component, renderer, currentSFContact)
                  case t: CallTransfer  => transferCard(t, component, renderer, currentSFContact)
                  case err: CallError   => errorCard(err, component, renderer, currentSFContact)
                  case u                => {
                    logger.debug("Event ${u} not considered, therefore rendering nothing")
                    nothing
                  }
              }
          ).render(renderer)
        })
      )
  }
}

class CallPanelMethods extends js.Object {

  import monix.execution.Scheduler.Implicits.global

  def logCaseComment(logOptions: Map[String, Option[SelectOption]], objectId: String) = {
    val component = this.asInstanceOf[CallPanelComponent]
    val caseCommentSelectionOpt =  SettingsUtil.getSelection[Single[Boolean]](LogCaseComment, LocalStorage)(singleSelectionBooleanFormat)
    val isCaseCommentEnabled = if (caseCommentSelectionOpt.isDefined) caseCommentSelectionOpt.get.selected.getOrElse(false) else false
      if (isCaseCommentEnabled) { 
        val comment = logOptions.get("Description").flatMap(a => a.map(_.value))
        CallPanel.logger.info("Logging Case Comment")
        Sforce.opencti.saveLog(
        SaveLogArguments[SaveLogValueObject](
          Some(
            CaseCommentLogObject(
              Some(objectId),
              comment.map(n => s"[Logged Call] ${n}")
            )
          ),
          Some(CallBackArgument(e => {
            Sforce.opencti.refreshView()
            CallPanel.logger.info("Success CaseComment")
            CallPanel.logger.debug("Refreshing")
            component.$root.$emit(
              "display-message",
              SnackBarMessage(
                "Call Logged",
                messageType = DisplayMessageType.Success
              )
            )
          }))
        )
      )
      } else {
        CallPanel.logger.info("Case Comments are disabled not logging Case Comment")
      }
  }

  def logCall(
      contact: Option[SFContact],
      accountId: Option[String],
      sfObject: Option[SFObject],
      selectedLogOptions: Map[String, Option[SelectOption]]
  ): EitherT[Future, List[ErrorMessage], SaveLogPayLoad] = {
    CallPanel.logger.info("Logging Call")
    import com.sludg.model.Models
    
    val result = EitherT[Promise, List[ErrorMessage], SaveLogPayLoad](Promise())
    val component = this.asInstanceOf[CallPanelComponent]
      
    val date = java.time.ZonedDateTime
      .now(ZoneId.of("GMT"))
      .format(DateTimeFormatter.ISO_LOCAL_DATE_TIME)

    def createDynamicTaskLogParams(selectedLogOptions: Map[String, Option[SelectOption]]): Seq[(String, String)] = {
      selectedLogOptions.map(a => a match {
        case (value, selOpt) => {
          (value -> selOpt.map(_.value).getOrElse(""))
        }
      }).toSeq
    }

    val objectId = sfObject.map(_.id).getOrElse("")
    val contactId = contact.map(_.id).getOrElse("")
    val accId = accountId.getOrElse("")

    // A lead is considered to be part of WhoId since it is an early form of contact
    val whoWhat: Seq[(String, String)] = if (sfObject.exists(_.RecordType == "Lead")) { 
      Seq(
        "WhoId" -> objectId,
      )
    } else {
      Seq(
      "WhatId" -> objectId,
      "WhoId" -> contactId,
     )
    } 
  
    def baseTaskLogFields: Seq[(String, String)] = Seq(
      "entityApiName" -> "Task",
      "AccountId" -> accId,
      "ActivityDate" -> date,
      "Subject" -> "Call",
      "TaskSubtype" -> "Call",
      "Status" -> "Completed",
      "IsArchived" -> "false",
      "IsClosed" -> "true",
      "IsDeleted" -> "false",
      "IsHighPriority" -> "false",
      "IsRecurrence" -> "false",
      "IsReminderSet" -> "false",
      "Priority" -> "Normal",
      ) ++ whoWhat

    CallPanel.logger.info(baseTaskLogFields.toString)
    CallPanel.logger.info(createDynamicTaskLogParams(selectedLogOptions).toString)

    def allTaskLogFields = baseTaskLogFields ++ createDynamicTaskLogParams(selectedLogOptions)
    CallPanel.logger.info("Task Log json object")
    
    val dynamicTaskLogObject = js.Dictionary.apply(allTaskLogFields:_*)
    CallPanel.logger.info(JSON.stringify(dynamicTaskLogObject))

    val taskLogObject = dynamicTaskLogObject.asInstanceOf[TaskLogObject]
    Sforce.opencti.saveLog(
      SaveLogArguments[SaveLogValueObject](
        Some(
          taskLogObject
        ),
        Some(CallBackArgument(e => {
          if (e.success) {
            CallPanel.logger.info("Log Successful")
            CallPanel.logger.info(JSON.stringify(e))
            CallPanel.logger.info("more than 1 contact found")
            logCaseComment(selectedLogOptions, objectId)
            Sforce.opencti.refreshView()
            result.value.success(Right(e.returnValue))
          } else {
            CallPanel.logger.info("debug: failure")
            component.$root.$emit(
              "display-message",
              SnackBarMessage(
                s"${e.errors.head.description}: Unable to log this call.",
                messageType = DisplayMessageType.Error
              )
            )
            e.errors.foreach(e => {
              CallPanel.logger.info(s"${e.code}: ${e.description}")
            })
            result.value.success(Left(e.errors.toList))
          }
        }))
      )
    )

    for {
      payload <- EitherT(result.value.future)
    } yield payload
  }
}

trait CallPanelProps extends VueProps {
  val currentCall: CallData
  val logOptions: js.UndefOr[Map[SelectOption, List[SelectOption]]]
  val token: SludgToken
}

object CallPanelProps {

  import scalajs.js.JSConverters._

  def apply(
      currentCall: CallData,
      logOptions: Option[Map[SelectOption, List[SelectOption]]] = None,
      token: SludgToken
  ) =
    js.Dynamic
      .literal(
        "currentCall" -> currentCall.asInstanceOf[js.Any],
        "logOptions" -> logOptions.map(_.asInstanceOf[js.Any]).orUndefined,
        "token" -> token.asInstanceOf[js.Any]
      ).asInstanceOf[CallPanelProps]
}
