package com.sludg.client.components

/**
  * @author Ali S. (CM9000)
  */

import com.sludg.Security
import com.sludg.models.Config.AppLink
import com.sludg.services.PhoneApi
import com.sludg.vue.{EventBindings, Vue, _}
import org.log4s.getLogger

import scala.scalajs.js

/**
  * Dialpad component for displaing dialer and making calls.
  */
object DialPad {


  import com.sludg.vue.RenderHelpers._

  private[components] val logger = getLogger

  // TODO: needs to be moved
  val tBody = namedTag[VueProps, EventBindings, ScopedSlots]("tbody")

  type DialPadComponent = VueComponent[_ <: DialPadProps, _ <: Slots, _ <: ScopedSlots] with DialPadData with DialPadMethods with js.Object with DialPadProps with WithRefs[_ <: Refs]

  def DialPadRenderer(registrationName: String) = namedTag[DialPadProps, EventBindings, ScopedSlots]("DialPad")

  def DialPadComponent(apiCalls: PhoneApi, security: Security, loaderEventBus: Vue, otherApps: List[AppLink]) = {

    val data = new DialPadData()
    val methods = new DialPadMethods()

    VueComponent.builder
      .withData(data)
      .withMethods(methods)
      .withProps(js.Array("totalSeconds").asInstanceOf[DialPadProps])
      .build(
        created = js.defined(c => {
          logger.info("DialPad created")
        }),
        components = js.Dynamic.literal(
        ),
        templateOrRender =
          Right((component, renderer) => {
            div(
              table(
                tBody(
                  tr(
                    td("1"),
                    td("2"),
                    td("3")
                  ),
                  tr(
                    td("4"),
                    td("5"),
                    td("6")
                  ),
                  tr(
                    td("7"),
                    td("8"),
                    td("9")
                  ),
                  tr(
                    td("-"),
                    td("0"),
                    td("*")
                  )
                )
              )
            ).render(renderer)
          })
      )
  }
}

class DialPadData extends js.Object {

}

trait DialPadProps extends VueProps {
  val totalSeconds: js.UndefOr[Int] = js.undefined
}

object DialPadProps {
  import scalajs.js.JSConverters._
  def apply(
             totalSeconds: Option[Int] = Some(0)
           ): DialPadProps = js.Dynamic.literal(
    "totalSeconds" -> totalSeconds.map(js.Any.fromInt).orUndefined
  ).asInstanceOf[DialPadProps]
}



class DialPadMethods extends js.Object {

}




