package com.sludg.client.pages.settings

import com.sludg.model.Models._
import com.sludg.model.SettingsModels.Configuration.{FieldLogging, ClickToCallDevice, CaseComment}
import com.sludg.model.SettingsModelsSerializers._
import com.sludg.client.pages.settings.SettingsUtil._
import com.sludg.client.pages.settings.SettingDisplayer.SettingDisplayerComponent
import com.sludg.client.pages.settings.SettingsMenuPage.SettingsMenuPageComponent
import com.sludg.vue.TemplatingHelpers.RenderFunction
import com.sludg.vue.VNode
import org.scalajs.dom.ext.LocalStorage
import play.api.libs.json.Json
import scala.concurrent.Promise
import cats.data.EitherT
import cats.implicits._
import com.sludg.salesforce.Sforce
import com.sludg.salesforce.RunApexArguments
import com.sludg.salesforce.CallBackArgument
import com.sludg.salesforce.RunApexCallbackPayload
import com.sludg.salesforce.ErrorMessage
import play.api.libs.json.JsSuccess
import scala.scalajs.js.JSON
import com.sludg.services.ApiCalls
import com.sludg.auth0.SludgToken
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import cats.data._
import cats.implicits._
import com.sludg.util.models.SilhouetteModels.UserPhone
import com.sludg.model.SettingsModels._
import com.sludg.model.SettingsModels.ConfigurationType.LogField
import com.sludg.vue.RenderHelpers.{div, nothing}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import com.sludg.vuetify.VuetifyComponents._
import org.scalajs.dom.raw.Event
import com.sludg.vue.RenderHelpers.{div, nothing, p, h3}
import com.sludg.vue.VueInstanceProperties.CreateElement
import com.sludg.vue.{RenderHelpers, _}
import com.sludg.vuetify.components._
import scala.scalajs.js
import com.sludg.services.{ApiCalls}
import com.sludg.model.SettingsModels.Setting
import com.sludg.vue.TemplatingHelpers.NodeModifier
import com.sludg.vuetify.components.grid.VGridPropValues.DisplayType
import com.sludg.model.Models
import scala.reflect.ClassTag
import cats.syntax.`package`.compose
import org.scalajs.dom.ext.Storage
import play.api.libs.json.Reads
import play.api.libs.json.JsResult
import play.api.libs.json.OFormat
import com.sludg.salesforce.OpenCti
import com.sludg.client.OpenCtiUtil
import monix.eval.TaskCircuitBreaker.Open
import com.sludg.salesforce.CaseCommentLogObject

/**
 * Settings Related Utility methods (don't rely on reactive data)
 */
object SettingsUtil {

  import com.sludg.vue.RenderHelpers._

    val vRadioGroup = namedTag[VueProps, EventBindings, ScopedSlots]("v-radio-group")
    val vRadio = namedTag[VueProps, EventBindings, ScopedSlots]("v-radio")

    // Will be used to filter log field settings implemented
    val CALL_LOG_SELECTION_IMPLEMENTED = List[String](
        "CallType",
        "Call_Outcome__c",
    )

    def getSelection[S](configurationType: ConfigurationType, localStorage: Storage)(implicit reads: OFormat[S]): Option[S] = {
      localStorage(configurationType.toString).map(json => {
        Json.parse(json).validate[S].asOpt match {
          case Some(v) => Some(v) 
          case _ => None
        }
      }).getOrElse(None)
    }

    /**
      * Gets Configurations Selections and their respective Options for based on the configType provided
      * Api in this case is a case class containing LocalStorage and ApiCalls to allow Local Settings as well as Remote settings
      * @param configType
      * @param api
      * @param token
      * @return
      */
    def getConfigurationOptionsAndSelection(configType: ConfigurationType, api: Api)(implicit token: SludgToken): Future[Option[Configuration[_]]] = {
      import com.sludg.model.SettingsModelsSerializers._
      defaultSettings.flatMap(s => s.configurations.find(config => config.configurationType == configType)).headOption match {
         case Some(a: FieldLogging) => {
          val selectionOpt =  getSelection[ListSelection[SelectOption]](configType, api.localStorage)(listSelectionSelectOptionFormat)

          val options = for (
            logOptions <- getCallLogOptions()
          ) yield {
            logOptions
          }

          val optionsOpt = options.value.map({
            case Right(v) => v
            case Left(v) => Nil
          })

          optionsOpt.map(opt => {
            val filteredOptions = opt.filter(a => a.fieldType == "TEXTAREA" || a.fieldType == "PICKLIST")
            Some(a.copy(options=filteredOptions, selection = selectionOpt.getOrElse(a.selection)))
          })
        } 
        case Some(a: ClickToCallDevice) => {
          import monix.execution.Scheduler.Implicits.global
          val tenantId = api.token.tenants(0)
          val extensionId = api.token.subscribers(0).ext.toInt 
          val devices = api.apiCalls.getUserPhones(tenantId, extensionId).map({
            case Right(listOfDevices) => {
              Some(a.copy(
              options=listOfDevices, 
              selection = a.selection.copy(selected = listOfDevices.find(u => u.defaultC2CPhone).headOption)
              ))
            }
            case Left(v) => None
          })
         devices
        }
        case _ => Future.successful(None)
      }
    }

    /**
      * Updates CaseComment settings selection data based on if it exists in LocalStorage or not
      * If the data exists it updates and returns new `List[Setting]` this then gets set to the reactive Data prop `setting`
      * If the data doesn't exist it defaults the selection data to false and returns the `List[Setting]`
      *
      * @param settings
      * @param updatedSelection
      * @param api
      * @return
      */
    def updateCaseCommentSelection(settings: List[Setting], updatedSelection: Option[Single[Boolean]], api: Api) = {
     settings.find(s => {
      s.isInstanceOf[CallLogSettings]
    }) match {
       case Some(settingFound) => {
         SettingsMenuPage.logger.info("Setting found")
         val index: Int = settings.indexOf(settingFound)
         val updatedSetting: Setting = settingFound match {
           case callLog: CallLogSettings => {
               callLog.configurations.find(config => config.isInstanceOf[CaseComment]) match {
               case Some(configFound: CaseComment) => {
                 val updatedConfigIndex = callLog.configurations.indexOf(configFound)
                 val updatedSelected = updatedSelection.map(_.selected).getOrElse(configFound.selection.selected.map(a => !a))
                 val caseCommentUpdated = configFound.copy(selection = configFound.selection.copy(selected = updatedSelected))

                 // Calling Save on the Updated Config
                 caseCommentUpdated.saveConfig(api)
                 callLog.copy(configurations = callLog.configurations.patch(updatedConfigIndex, List(caseCommentUpdated),1))
               }
               case _ => callLog
             }
          }
          case a => a
        }
        settings.patch(index, List(updatedSetting), 1)
       }
       case None => {
         SettingsMenuPage.logger.info("Setting not found")
         settings
       }
    }}

    val defaultSettings: List[Setting] = List(
        CallLogSettings(List(
            FieldLogging(
                configurationType = ConfigurationType.LogField,
                displayType  = MultiSelect,
                options = Nil,
                selection = ListSelection(ConfigurationType.LogField, Nil),
            ),
            CaseComment(
                configurationType = ConfigurationType.LogCaseComment,
                    displayType  = Option,
                    options = Nil,
                    selection = Single(ConfigurationType.LogCaseComment, Some(false)),
            ),
        )),
        CallOriginationSettings(List(
          ClickToCallDevice(
              configurationType = ConfigurationType.ClickToCallDeviceSelect,
              displayType  = Option,
              options = Nil,
              selection = Single(ConfigurationType.ClickToCallDeviceSelect, None),
          )
        ))
    )

   def getCallLogOptions(): EitherT[Future, List[ErrorMessage], List[SelectOption]] = {    
    var result = EitherT[Promise, List[ErrorMessage], List[SelectOption]](Promise())

    Sforce.opencti.runApex(
      RunApexArguments(
        "OpenCtiUtil",
        "getTaskFields",
        "sObjectName=Task",
        Some(CallBackArgument[RunApexCallbackPayload](e => {
          if (e.success) {
            val listOfFields = e.returnValue.runApex
            val listOfOptions: List[SelectOption] = Json
            .parse(listOfFields.toString)
            .validate[List[SelectOption]] match {
              case JsSuccess(a: List[SelectOption], _) => a
              case _ => Nil
            }

            val listOfFieldsConvertedL: List[SelectOption] = listOfOptions
            result.value.success(Right(listOfFieldsConvertedL))
          } else {
            JSON.stringify(e.errors)
            result.value.success(Left(e.errors.toList))
          }
        }))
      )
    )

    for {
      callLogSetting <- EitherT(result.value.future)
    } yield callLogSetting
  }

    def getDefaultSettings(): List[Setting] = {
        defaultSettings
    }

    def selectionToList: SelectionType => List[Any] = selectionType => selectionType match {
      case ListSelection(_, selected) => selected
      case Single(_, selected) => selected.map(s => List(s)).getOrElse(Nil)
    }

}