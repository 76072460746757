package com.sludg.client

import com.sludg.client.pages.main.PhonePage
import com.sludg.vue._
import org.log4s.getLogger
import com.sludg._
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.model.Models.SalesforcePhoneConfig

import scala.scalajs.js
import com.sludg.helpers.Client.initialize
import com.sludg.services.{ApiCalls, PhoneApi}
import com.sludg.client.pages.settings.SettingsMenuPage
import com.sludg.client.pages.settings.SettingDisplayer
import com.sludg.services.Websockets

object Client {

  private[this] val logger = getLogger
  private val vuetifyAssets = com.sludg.vuetify.VuetifyAssets
  import com.sludg.helpers.Client.initialize

  case class InstanceData(token: SludgToken)

  def apply(security: Security, config: SalesforcePhoneConfig, otherApps: List[AppLink], phoneApi: PhoneApi, appContainer: String, apiCalls: ApiCalls, token: SludgToken) = {
    logger.info("Client")
    logger.debug("Client processes. We don't care about security >:)")

    val apiCallBus = new Vue(js.undefined)

    val phonePage = PhonePage.PhonePageComponent(phoneApi, new Websockets(config.apiConfig), security, apiCallBus, otherApps, token)(scala.concurrent.ExecutionContext.Implicits.global)
    val settings = SettingsMenuPage.SettingsMenuPageComponent(apiCalls, security, apiCallBus, otherApps, token)
    val settingDisplayer = SettingDisplayer.SettingDisplayerComponent(apiCalls, security, apiCallBus, otherApps, token)

    initialize(config.vuetify, appContainer,
      List(
        VueRoute("/", name = Some("PhonePage"), component = Some(phonePage)),
        VueRoute("/settings", name = Some("Settings"), component = Some(settings)),
        VueRoute("/settings/:settingName", name = Some("SettingDisplayer"), component= Some(settingDisplayer)),
        VueRoute("*", redirect = Some("/"))
      ),
      )
  }
}