package com.sludg.client

import com.sludg.Security
import com.sludg.components.VTSLApp
import com.sludg.helpers.AppSetup.VtslAppProps
import com.sludg.models.Config.AppLink
import com.sludg.salesforce.{CallBackArgument, Sforce}
import com.sludg.services.{ApiCalls, PhoneApi}
import com.sludg.util.models.SilhouetteModels.Tenant
import com.sludg.vue._
import org.scalajs.dom.Event
import com.sludg.vuetify.VuetifyComponents._
import com.sludg.vuetify.components.VListTileProps
import org.log4s.getLogger
import com.sludg.FieldExtractor

import scala.scalajs.js

/** Layer on top of the VtslApp to allow for SalesforcePhone method `disableClickToDial` on logout
  */
object VtslPhone {
  import com.sludg.helpers._

  private[this] val logger = getLogger

  import RenderHelpers._

  def VtslPhoneRenderer(registrationName: String) =
    namedTag[VtslAppProps, EventBindings, ScopedSlots](registrationName)

  val appRenderer = VTSLApp.appRenderer("VtslApp")

  import com.sludg.helpers.AppSetup.VtslAppScopedSlots

  def VtslPhoneComponent(
      apiCalls: ApiCalls,
      security: Security,
      apiCallBus: Vue,
      otherApps: List[AppLink]
  ) = VueComponent.builder
    .withScopedSlots[VtslAppScopedSlots]
    .withPropsAs[VtslAppProps]
    .build(
      created = js.defined(c => {
        logger.debug("VTSL Phone created")
      }),
      components = js.defined(
        js.Dynamic.literal(
          "VtslApp" -> VTSLApp.createApp(
            "Salesforce Phone",
            apiCalls,
            apiCallBus,
            otherApps,
            Some(buildinfo.sbtinfo.version),
            None,
            _ => {
              Sforce.opencti.disableClickToDial(CallBackArgument(e => {
                if (e.success) {
                  logger.info("Successfully disabled click to dial")
                } else {
                  logger.debug("Failed to disable click to dial")
                }
              }))
              security.logout()
            },
            false
          )
        )
      ),
      updated = js.defined(c => {
        logger.debug("Vtsl Phone updated")
      }),
      templateOrRender = Right((component, renderer) => {
        div(
          appRenderer(
            RenderOptions(
              props = Some(
                new VtslAppProps(
                  // title = component.title,
                  spacedToolbar = component.spacedToolbar,
                  displayTenantSelector = false,
                  vContentIsFluid = true,
                  temporaryDrawer = true
                )
              ),
              scopedSlots = Some(
                new VtslAppScopedSlots {
                  override val toolbarLeft: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbarLeft
                  override val toolbar: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbar
                  override val toolbarExtension: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    component.$scopedSlots.toolbarExtension
                  override val drawer: js.UndefOr[js.Function1[Option[Tenant], VNode]] =
                    js.defined(tenant => {
                      logger.debug("Drawer")
                      vList(
                        vListTile(
                          vListTileAction(vIcon("settings")),
                          vListTileAction(
                            "Settings"
                          )
                        )(
                          RenderOptions[VListTileProps, EventBindings, ScopedSlots](
                            props = Some(
                              VListTileProps(
                                to = Some(Left("/settings"))
                              )
                            )
                          )
                        )
                      ).render(renderer)
                    })
                  override val default: js.UndefOr[js.Function1[Option[Tenant], VNode]] = {
                    logger.debug("Default")
                    component.$scopedSlots.default
                  }
                }
              )
            )
          )
        ).render(renderer)
      })
    )
}

