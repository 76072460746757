package com.sludg.model

import com.sludg.models.Config._
import play.api.libs.json.{Format, Json}
import scala.scalajs.js._
import scala.scalajs.js
import scala.scalajs.js.annotation.JSExportAll
import com.sludg.util.models.SilhouetteModels.UserPhone
import com.sludg.vue.VueComponent
import com.sludg.vue.TemplatingHelpers.NodeRenderer
import com.sludg.vue.RenderHelpers._
import com.sludg.vue.VNode
import com.sludg.models.CallControlModels.CallEvent
import com.sludg.salesforce.{CallType, SFContact}
import com.sludg.vuetify.components.grid.VGridPropValues.DisplayType
import com.sludg.client.pages.settings.SettingsUtil
import cats.implicits._
import scala.concurrent.Future
import scala.concurrent.ExecutionContext.Implicits.global
import cats.data._
import cats.instances.future.catsStdInstancesForFuture
import com.sludg.vue.ScopedSlots
import com.sludg.vue.VueProps
import com.sludg.vue.EventBindings
import com.sludg.services.ApiCalls
import org.scalajs.dom.ext.Storage
import org.scalajs.dom.ext.LocalStorage
import java.rmi.server.LoaderHandler
import com.sludg.auth0.SludgToken
import com.sludg.vuetify.components.VComboboxProps
import play.api.libs.json.Writes
import play.api.libs.json.JsNull
import play.api.libs.json.JsString
import play.api.libs.json.JsError
import play.api.libs.json.JsSuccess
import play.api.libs.json.Reads
import play.api.libs.json.JsResult
import scala.scalajs.js.timers.SetIntervalHandle
import com.sludg.salesforce._

object Models {
  import org.scalajs.dom.ext.LocalStorage
  import com.sludg.json.Deserializer._
  import com.sludg.model.SettingsModelsSerializers._

  @JSExportAll
  case class JWTSub(sid: Int, tid: String, ext: String, admin: Boolean)

  implicit val salesforcePhoneConfigConfigFormat: Format[SalesforcePhoneConfig] =
    Json.format[SalesforcePhoneConfig]
  case class SalesforcePhoneConfig(
      auth0: AlmostAuth0Config,
      vuetify: VueColors,
      apiConfig: ApiConfig,
      otherApps: List[AppLink],
      logLevel: Option[org.log4s.LogLevel]
  ) extends Config

  // Used to provide ApiCalls and Local storage to save/get configurations
  case class Api(apiCalls: ApiCalls, token: SludgToken, localStorage: Storage = LocalStorage)

  // Mapping of salesforce model for Log Field options
  case class SelectOption(value: String, label: String, fieldType: String)

  // Model of each Call and its salesforce related properties
  case class CallData(
      Id: String,
      from: String,
      lastEvent: CallEvent,
      callActive: Boolean = false,
      outgoing: Boolean = false,
      transferred: Boolean = false,
      // Salesforce types
      callType: CallType,
      sfContact: Option[SFContact] = None,
      searchedSalesforce: Boolean = false,
      selectedLogOptions: Map[String, Option[SelectOption]] = Map(),
      duration: Option[SetIntervalHandle] = None,
      selectableSFObjects: List[Either[VComboboxProps.StaticItem, SFObject]] = Nil,
      relatedSfObjects: List[SFObject] = Nil,
      relatedContacts: List[SFContact] = Nil,
      selectedSfObject: Option[SFObject] = None
  )

}
