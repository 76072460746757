package com.sludg.client.pages.helper


import com.sludg.helpers.AppSetup._
import com.sludg.auth0.SludgToken
import com.sludg.models.Config.AppLink
import com.sludg.services.{ApiCalls, EventBus, EventBusEvent, PhoneApi}
import com.sludg.Security
import com.sludg.salesforce._
import org.log4s.getLogger
import monix.execution.Scheduler.Implicits.global
import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.JSON
import org.scalajs.dom.ext.LocalStorage
import org.scalajs.dom.window
import js.JSConverters._
import java.util.Timer
import com.sludg.util.TokenUtil

object Helper {

  def tokenValidator(sec: Security, apiCalls: ApiCalls, token: SludgToken): Future[Option[SludgToken]] = {
      val isTokenExpired = !Security.checkNotExpired(Security.getExpirationInMillis(token.tokenString))
      if (isTokenExpired) {
        sec.checkSession().map(tokenOpt => tokenOpt.map(responseToken => new SludgToken(responseToken.accessToken)))
      } else {
        Future.successful(Some(new SludgToken(token.tokenString)))
      }
    }
}
